import React, { useState, useEffect } from 'react';
import authService from './api-authorization/AuthorizeService';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Table, Progress, Button } from 'reactstrap';
import classnames from 'classnames';
import DeleteItemModal from './Modals/DeleteItemModal';
import AddItemModal from './Modals/AddItemModal';
import DeleteItem from './API Calls/DeleteItem';
import NewItemModalAdmin from './Modals/NewItemModalAdmin';
import EditItemModal from './Modals/EditItemModal';
import EditSPModal from './Modals/EditSPModal';
import DeleteSPModal from './Modals/DeleteSPModal';

import "./Items.css";
import CsvDownload from 'react-json-to-csv'


var allItems = []
var ALLpurchases = []
var purchaseCount

const Items = () => {
    const [activeTab, setActivateTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActivateTab(tab);
    }

    const [data, setData] = useState({ items: [], fitems: [], iitems: [], serviceProviders: [], purchaseDetails: [], user: '', loading: true, isAdmin: 'false' });

    async function fetchData() {

        //Gets items
        const token = await authService.getAccessToken();
        const itemsResponse = await fetch('api/Items', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await itemsResponse.json();
        var unfulfilledItems = data.filter(item => (item.quantityReceived < item.amountRequested))
        unfulfilledItems = unfulfilledItems.filter(item => (item.active === true))
        unfulfilledItems.sort((a, b) => (a.productShortName.toLowerCase() > b.productShortName.toLowerCase()) ? 1 : -1)
        allItems = unfulfilledItems

        var inactive = data.filter(item => (item.active === false))

        var fulfilledItems = data.filter(item => (item.quantityReceived >= item.amountRequested))
        fulfilledItems.sort((a, b) => (a.productShortName.toLowerCase() > b.productShortName.toLowerCase()) ? 1 : -1)

        // Get Service Providers
        const usersResponse = await fetch('api/AspNetUsers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const usersData = await usersResponse.json();
        var serviceProviders = []


        usersData.forEach(function (theitem) {
            if (theitem.isServiceProvider === true) {
                serviceProviders.push(theitem)
            }
        })

        console.log(serviceProviders)
        //Get user details
        const token2 = await authService.getAccessToken();
        const authUser2 = await authService.getUser();
        const username = authUser2 && authUser2.name;
        const response2 = await fetch('api/AspNetUsers/littleinfo/' + username, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token2}` }
        });
        const persondata = await response2.json();


        //Get purchase details
        const response3 = await fetch('api/PurchasedItems/GetPurchasedDetails', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        let purchaseDetails = await response3.json();

        purchaseDetails.sort((a, b) => (a.purchasedDate < b.purchasedDate) ? 1 : -1)

        ALLpurchases = purchaseDetails

        purchaseCount = purchaseDetails.length

        purchaseDetails.forEach(function (element) {
            if (element.firstName == null) {
                element.id = serviceProviders[serviceProviders.findIndex(x => x.id === element.id)].organizationName
            } else {
                element.id = element.firstName + " " + element.lastName;
            }
            element.aspNetUSerID = serviceProviders[serviceProviders.findIndex(x => x.id === element.aspNetUSerID)].organizationName;
            element.purchasedDate = element.purchasedDate.split("T")[0].split('-')[1] + "/" + element.purchasedDate.split("T")[0].split('-')[2] + "/" + element.purchasedDate.split("T")[0].split('-')[0];

            delete element.firstName; delete element.lastName;
        })
        purchaseDetails = JSON.parse(JSON.stringify(purchaseDetails).split('"aspNetUSerID":').join('"serviceProvider":'));
        purchaseDetails = JSON.parse(JSON.stringify(purchaseDetails).split('"id":').join('"buyer":'));
       

        setData({ items: unfulfilledItems, fitems: fulfilledItems, iitems: inactive, serviceProviders: serviceProviders, purchaseDetails: purchaseDetails, user: authUser2.sub, loading: false, isAdmin: persondata.admin });

    };

    useEffect(() => {
        fetchData();
    }, []);

    const filterServiceProvider = (userId) => {
        if (userId === "all") {
            setData({ items: allItems, serviceProviders: data.serviceProviders, loading: false });
        }
        else {
            const filteredItems = allItems.filter(i => i.userId === userId)
            setData({ items: filteredItems, serviceProviders: data.serviceProviders, loading: false });
        }
    }

    const getDataAfterDelete = index => {
        data.iitems.push(data.items[index])
        data.items.splice(index, 1)
        setData({ items: data.items, fitems: data.fitems, iitems: data.iitems, serviceProviders: data.serviceProviders, purchaseDetails: data.purchaseDetails, isAdmin: data.isAdmin, user: data.user, loading: false });
    }

    const getDataAfterAdd = index => {
        data.items.push(data.iitems[index])
        data.iitems.splice(index, 1)
        data.items.sort((a, b) => (a.productShortName.toLowerCase() > b.productShortName.toLowerCase()) ? 1 : -1)
        setData({ items: data.items, fitems: data.fitems, iitems: data.iitems, serviceProviders: data.serviceProviders, purchaseDetails: data.purchaseDetails, isAdmin: data.isAdmin, user: data.user, loading: false });
    }

    const filterItems = (filterValue) => {
        let filteredPurchases
        if (filterValue == "ALL") {
            filteredPurchases = ALLpurchases
        } else if (filterValue == "TODAY") {
            var d = new Date(); d.setDate(d.getDate());
            filteredPurchases = ALLpurchases.filter(i => new Date(i.purchasedDate) > d)
        } else if (filterValue == "7 DAYS") {
            var d = new Date(); d.setDate(d.getDate() - 7);
            filteredPurchases = ALLpurchases.filter(i => new Date(i.purchasedDate) > d)
        } else if (filterValue == "30 DAYS") {
            var d = new Date(); d.setDate(d.getDate() - 30);
            filteredPurchases = ALLpurchases.filter(i => new Date(i.purchasedDate) > d)
        } else {
            filteredPurchases = ALLpurchases
        }
        purchaseCount = filteredPurchases.length

        filteredPurchases = JSON.parse(JSON.stringify(filteredPurchases).split('"id":').join('"buyer":'));
        filteredPurchases = JSON.parse(JSON.stringify(filteredPurchases).split('"aspNetUSerID":').join('"serviceProvider":'));

        setData({ items: data.items, fitems: data.fitems, iitems: data.iitems, serviceProviders: data.serviceProviders, purchaseDetails: filteredPurchases, isAdmin: data.isAdmin, user: data.user, loading: false });
    }

    const renderItemsTable = () => {
        return (
    
            <Table aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Service Provider</th>
                        <th>Item</th>
                        <th>Date Requested</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data.items.map((item, index) =>
                        <tr key={item.itemId}>
                            <td className="AD1" id="itemwidth">
                                <Row>
                                    <Col xs="12" sm="12" md="4" lg="4"><img className="pic" border="0" src={"https://homeaidutahstorage.blob.core.windows.net/organizationpics/" + data.serviceProviders[data.serviceProviders.findIndex(x => x.id === item.userId)].organizationLogo} alt={data.serviceProviders[data.serviceProviders.findIndex(x => x.id === item.userId)].organizationName} /></Col>
                                    <Col xs="12" sm="12" md="8" lg="8">{data.serviceProviders[data.serviceProviders.findIndex(x => x.id === item.userId)].organizationName}</Col>
                                </Row>
                            </td>
                            <td className="AD1" id="itemwidth">
                                <Row>
                                    <Col xs="12" sm="12" md="4" lg="4"><img className="pic" border="0" src={'//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=' + item.itemImage + '&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=homeaidessent-20&language=en_US'}></img></Col>
                                    <Col xs="12" sm="12" md="8" lg="8">{item.productShortName}</Col>
                                </Row>
                            </td>
                            <td className="AD1" id="daterequestedwidth">
                                {item.dateRequested.split("T")[0].split('-')[1]}/{item.dateRequested.split("T")[0].split('-')[2]}/{item.dateRequested.split("T")[0].split('-')[0]}
                            </td>
                            <td className="AD1" id="actionwidth">
                                <div className="center-buttons">
                                    <EditItemModal item={item} handleClick={() => fetchData()} />
                                    <DeleteItemModal itemName={item.itemName} handleClick={() => { DeleteItem(item.itemId); getDataAfterDelete(index) }} />
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

   
    const renderItemsTable2 = () => {
        return (
            <Table aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Purchased Date</th>
                        <th>Service Provider</th>
                    </tr>
                </thead>
                <tbody>
                    {data.purchaseDetails.map((item, index) =>
                        <tr key={item.purchaseItemsId}>
                            <td className="AD2">
                                {item.buyer}
                            </td>
                            <td className="AD2">
                                {item.itemName}
                            </td>
                            <td className="AD2">
                                {item.amount} -- ${item.orderTotal.toFixed(2)}
                            </td>
                            <td className="AD2">
                                {item.purchasedDate}
                            </td>
                            <td className="AD2">
                                {item.serviceProvider}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            );
    }

    const renderItemsTable3 = () => {
        return (
            <Table aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Service Provider</th>
                        <th>Item</th>
                        <th>Date Requested</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data.iitems.map((item, index) =>
                        <tr key={item.itemId}>
                            <td className="AD1" id="itemwidth">
                                <Row>
                                    <Col xs="12" sm="12" md="4" lg="4"><img className="pic" border="0" src={"https://homeaidutahstorage.blob.core.windows.net/organizationpics/" + data.serviceProviders[data.serviceProviders.findIndex(x => x.id === item.userId)].organizationLogo} alt={data.serviceProviders[data.serviceProviders.findIndex(x => x.id === item.userId)].organizationName} /></Col>
                                    <Col xs="12" sm="12" md="8" lg="8">{data.serviceProviders[data.serviceProviders.findIndex(x => x.id === item.userId)].organizationName}</Col>
                                </Row>
                            </td>
                            <td className="AD1" id="itemwidth">
                                <Row>
                                    <Col xs="12" sm="12" md="4" lg="4"><img className="pic" border="0" src={'//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=' + item.itemImage + '&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=homeaidessent-20&language=en_US'}></img></Col>
                                    <Col xs="12" sm="12" md="8" lg="8">{item.productShortName}</Col>
                                </Row>
                            </td>
                            <td className="AD1" id="daterequestedwidth">
                                {item.dateRequested.split("T")[0].split('-')[1]}/{item.dateRequested.split("T")[0].split('-')[2]}/{item.dateRequested.split("T")[0].split('-')[0]}
                            </td>
                            <td className="AD1" id="actionwidth">
                                <div className="center-buttons">
                                    <EditItemModal item={item} handleClick={() => fetchData()} />
                                    <AddItemModal itemName={item.itemName} handleClick={() => { DeleteItem(item.itemId); getDataAfterAdd(index) }} />
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

    const renderItemsTable4 = () => {
        return (
            <Table aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Service Provider</th>
                        <th>Address</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data.serviceProviders.map((sp, index) =>
                        <tr key={sp.id}>
                            <td className="SP1" id="itemwidth">
                                <Row>
                                    <Col xs="12" sm="12" md="8" lg="8">{sp.organizationName}</Col>
                                </Row>
                            </td>
                            <td className="SP1" id="itemwidth">
                                <Row>
                                    <Col xs="12" sm="12" md="8" lg="8">{sp.address} {sp.city}, {sp.state} {sp.zip}</Col>
                                </Row>
                            </td>
                            <td className="SP1" id="actionwidth">
                                <div className="center-buttons">
                                    <EditSPModal item={sp} handleClick={() => fetchData()} />
                                    <DeleteSPModal item={sp} handleClick={() => fetchData()} />
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

    let contentTable = data.loading
        ? <p><em>Loading...</em></p>
        : renderItemsTable(data.items);

    let contentTable2 = data.loading
        ? <p><em>Loading...</em></p>
        : renderItemsTable2(data.fitems);

    let contentTable3 = data.loading
        ? <p><em>Loading...</em></p>
        : renderItemsTable3(data.iitems);

    let contentTable4 = data.loading
        ? <p><em>Loading...</em></p>
        : renderItemsTable4(data.iitems);

    var isA; 
    if (data.isAdmin) {
        isA =
            <div>
            <Row>
                <Col xs="7" sm="8" md="4" lg="4">
                    <h3 id="tableLabel" className="blue-title">Admin Portal</h3>
                </Col>
            </Row>
            <Nav tabs>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                        Items Requested
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                        List of Recent Purchases
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                        InActive Items
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }}>
                        Service Providers
                    </NavLink>
                </NavItem>


                <NewItemModalAdmin handleClick={() => fetchData()} />
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <div>
                        {contentTable}
                    </div>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col xs="0" sm="3" md="4" lg="4" > <CsvDownload data={data.purchaseDetails} filename="PurchaseData.csv"> Download Purchase Data</CsvDownload></Col>
                        <Col xs="6" sm="5" md="4" lg="4">
                            <select className="btn btn-dropdown dropdown-toggle" onChange={e => filterItems(e.currentTarget.value)} defaultValue={'ALL'}>
                                <option className="hidden-option" value="DEFAULT" disabled>Sort</option>
                                <optgroup label="Date">
                                    <option value="ALL">All</option>
                                    <option value="TODAY">Today</option>
                                    <option value="7 DAYS">Last 7 Days</option>
                                    <option value="30 DAYS">Last 30 Days</option>
                                </optgroup>
                            </select>
                        </Col>
                        <Col xs="6" sm="4" md="4" lg="4" style={{ paddingTop: '5px' }}>Number of Orders: {purchaseCount}</Col>
                    </Row>
                    <div>
                        {contentTable2}
                    </div>
                </TabPane>
                <TabPane tabId="3">
                    <div>
                        {contentTable3}
                    </div>
                </TabPane>
                <TabPane tabId="4">
                    <div>
                        {contentTable4}
                    </div>
                </TabPane>
            </TabContent>
            </div>
    } else {
        isA = <h1>You do not have access to this page.</h1>
    }

    return (
        <div>         
            {isA}
        </div>
    );
}

export default Items;
