import React, { useState } from 'react';
import { Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, InputGroup, InputGroupAddon } from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import authService from '../api-authorization/AuthorizeService';
import "../Items.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NewItemModal = ({ user, handleClick }) => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd

    const [modal, setModal] = useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);

    const handleClickSuccess = () => {
        setOpenSuccess(true);
    };

    const handleClickError = () => {
        setOpenError(true);
    };

    const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccess(false);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenError(false);
    };


    const toggle = () => setModal(!modal);

    var formData = {
        "itemName": "",
        "productShortName": "",
        "price": 0,
        "amountRequested": 0,
        "quantityReceived": 0,
        "dateRequested": today,
        "lastModified": today,
        "itemUrl": "",
        "userId": user
    }

    /*const onSubmit = () => {
        console.log(formData);
        if (!formData["itemUrl"].includes("amazon.com")) {
            alert("INVALID URL")
        }
        else {
            toggle();
        }
    }*/

    function handleChange(event) {
        const property = event.target.name

        if (property === "amountRequested") {
            formData[property] = parseInt(event.target.value)
        }
        else if (property === "price") {
            formData[property] = parseFloat(event.target.value)
        }
        else {
            formData[property] = event.target.value
        }
    }

    function handleSubmit() {
        if (formData.itemUrl.indexOf("dp/") == -1 && formData.itemUrl.indexOf("gp/product/") == -1) {
            setOpenError(true);
        } else {
            async function addItem() {
                const token = await authService.getAccessToken();
                await fetch('api/Items/Create', {
                    method: "POST",
                    headers: !token ? {} : {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                });
            }
            addItem();
            handleClick();
            toggle();
        }
    }

    return (
        <>
            <Col className="newitembtn">
                <Button className="align-right btn-primary" onClick={toggle}>New Item +</Button>
            </Col>

            <Modal isOpen={modal} centered={true} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    New Item
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="itemName">Full Item Name (as displayed on Amazon)</Label>
                        <Input name="itemName" id="itemName" onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label for="productShortName">Item Display Name</Label>
                        <Input name="productShortName" id="productShortName" onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label for="price">Price</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                            <Input name="price" id="price" type="number" step=".01" onChange={handleChange} required />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amountRequested">Amount Requested</Label>
                        <Input name="amountRequested" id="amountRequested" type="number" min="1" step="1" onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label for="itemUrl">Amazon Link</Label>
                        <Input name="itemUrl" id="itemUrl" onChange={handleChange} required />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <div className="modalFooter">
                        <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                        <Button color="success" onClick={handleSubmit}>Submit</Button>
                    </div>
                </ModalFooter>
            </Modal>



            <Snackbar open={openSuccess} autoHideDuration={8000} onClose={handleCloseSuccess}>
                <Alert onClose={handleCloseSuccess} severity="success">
                   Item Added!
                </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={8000} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="error">
                    Link Missing or Wrong. Please do not use a short link. Try copying and pasting your short link into your browser and then copying the longer product link and pasting here.
                </Alert>
            </Snackbar>
        </>
    );
}

export default NewItemModal;