import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import "../Items.css";

const EditItemModal = ({ item, handleClick }) => {
    const [modal, setModal] = useState(false);
    const [disable, setDisable] = useState(false);

    const toggle = () => setModal(!modal);


    var formData = {
        "itemId": item.itemId,
        "productShortName": item.productShortName,
        "price": parseFloat(item.price),
        "amountRequested": parseInt(item.amountRequested),
        "itemUrl": item.itemUrl
    }



    function handleChange(event) {
        const property = event.target.name

        if (property === "productShortName") {
            formData[property] = event.target.value
            if (event.target.value === null) {
                setDisable(true);
            }
            else {
            }
        }
        else if (property === "amountRequested") {
            formData[property] = parseInt(event.target.value)
            if (event.target.value === null) {
                setDisable(true);
            }
            else { }

        }
        else if (property === "price") {
            formData[property] = parseFloat(event.target.value)
            if (event.target.value === null) {
                setDisable(true);
            }
            else {
            }
        }
        else if (property === "itemUrl") {
            formData[property] = event.target.value
            if (event.target.value === null) {
                setDisable(true);
            }
            else {
            }
        }
        else {
            formData[property] = event.target.value

        }
    }

    function handleSubmit() {
        async function editItem() {
            const token = await authService.getAccessToken();
            await fetch('api/Items/UpdateRequestedItem', {
                method: "POST",
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
        }
        //console.log(formData);
        editItem();
        handleClick();
        toggle();
    }

    return (

        <>
            <Button className="margin-right" color="secondary" onClick={toggle}>Edit</Button>
            
            <Modal isOpen={modal} centered={true} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    Editing: {item.productShortName}
                </ModalHeader>
                <ModalBody style={{ paddingBottom: '10px' }}>
                    <FormGroup>
                        <Label for="productShortName">Product Name</Label>
                        <Input name="productShortName" id="productShortName" type="text"  onChange={handleChange} required defaultValue={item.productShortName} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="itemUrl">Product Link</Label>
                        <Input name="itemUrl" id="itemUrl" type="text" onChange={handleChange} required defaultValue={item.itemUrl} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="amountRequested">Amount Requested</Label>
                        <Input name="amountRequested" id="amountRequested" type="number" min="1" step="1" onChange={handleChange} required defaultValue={item.amountRequested} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="price">Price</Label>
                        <Input name="price" id="price" type="number" min="1" step="1" onChange={handleChange} required defaultValue={item.price} />
                    </FormGroup>
                    <p style={{textAlign: "center", paddingBottom: '0px'}}><strong>After clicking submit please refresh the page to see your changes</strong></p>
                </ModalBody>
                <ModalFooter>
                    <div className="modalFooter">
                        <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                        <Button color="success" disabled={disable} onClick={handleSubmit}>Submit</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default EditItemModal;