import React, {useState, useEffect } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Row, Col } from 'reactstrap';
import {
    useParams
} from "react-router-dom";
//import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ConfirmPurchaseModal from './Modals/ConfirmPurchaseModal';

var allItems = []

const DonateItems = () => {
    const [data, setData] = useState({ items: [], serviceProviders: [], loading: true, serName: useParams() });

    let id = ''
    let name = useParams().name

    const delay = ms => new Promise(res => setTimeout(res, ms));

    useEffect(() => {
        async function fetchData() {
            const token = await authService.getAccessToken();
            const authUser = await authService.getUser();

            // Get Items
            const itemsResponse = await fetch('api/Items', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const itemsData = await itemsResponse.json();
            var unfulfilledItems = itemsData.filter(item => (item.quantityReceived < item.amountRequested))
            unfulfilledItems = unfulfilledItems.filter(item => (item.active === true))

            unfulfilledItems.sort((a, b) => (a.productShortName.toLowerCase() > b.productShortName.toLowerCase()) ? 1 : -1)
            
            allItems = unfulfilledItems

            // Get Service Providers
            const usersResponse = await fetch('api/AspNetUsers', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const usersData = await usersResponse.json();
            var serviceProviders = []

            unfulfilledItems.map(item => {
                const user = usersData.find(user => user.id === item.userId)
                if (!serviceProviders.includes(user)) {
                    serviceProviders.push(user)
                }
            })

            //console.log(unfulfilledItems)
            serviceProviders.sort((a, b) => (a.organizationName.toLowerCase() > b.organizationName.toLowerCase()) ? 1 : -1)

            if (data.serName.name != null) {
                //Find ID from name then filter
                id = serviceProviders.filter(i => i.organizationName === data.serName.name)[0].id
                const newItems = filterServiceProviderStart(id)

                if (authUser === null) {
                    setData({ items: newItems, serviceProviders: serviceProviders, token: !token ? "" : token, authloading: false, serName: id });
                } else {
                    setData({ items: newItems, serviceProviders: serviceProviders, authUserId: authUser.sub, token: !token ? "" : token, authloading: false, serName: id });
                }
            } else {
                if (authUser === null) {
                    setData({ items: unfulfilledItems, serviceProviders: serviceProviders, token: !token ? "" : token, authloading: false });
                } else {
                    setData({ items: unfulfilledItems, serviceProviders: serviceProviders, authUserId: authUser.sub, token: !token ? "" : token, authloading: false, serName: id });
                }
            }

        };
        fetchData();
    }, []);

    const filterServiceProviderStart = (userId) => {
        const filteredItems = allItems.filter(i => i.userId === userId)
        return filteredItems   
    }

    const filterServiceProvider = (userId) => {
        if (userId === "all") {
            setData({ items: allItems, serviceProviders: data.serviceProviders, loading: false, serName: userId });
        }
        else {
            const filteredItems = allItems.filter(i => i.userId === userId)
            setData({ items: filteredItems, serviceProviders: data.serviceProviders, loading: false, serName: userId });
        }   
    }

    const sortItems = (sortBy) => {
        if (sortBy === "a-z") {
            setData({ items: data.items.sort((a, b) => (a.productShortName.toLowerCase() > b.productShortName.toLowerCase()) ? 1 : -1), serviceProviders: data.serviceProviders, loading: false });
        }
        else if (sortBy === "z-a") {
            setData({ items: data.items.sort((a, b) => (a.productShortName.toLowerCase() < b.productShortName.toLowerCase()) ? 1 : -1), serviceProviders: data.serviceProviders, loading: false });
        }
        else if (sortBy === "low-high") {
            setData({ items: data.items.sort((a, b) => (a.price > b.price) ? 1 : -1), serviceProviders: data.serviceProviders, loading: false });
        }
        else if (sortBy === "high-low") {
            setData({ items: data.items.sort((a, b) => (a.price < b.price) ? 1 : -1), serviceProviders: data.serviceProviders, loading: false });
        }
    }

    const renderItems = () => {
        return (
            <>
                {data.items.map(item =>
                    <ConfirmPurchaseModal key={item.itemId} itemobj={item} allItems={data.items} user={data.serviceProviders.find(user => user.id === item.userId)} authUserId={data.authUserId} token={data.token} />
                )}
            </>
        );
    }
    const renderServiceProviders = () => {
        return (
            <>
                <select className="btn btn-dropdown dropdown-toggle" value={data.serName} onChange={e => filterServiceProvider(e.currentTarget.value)}>
                    <option className="hidden-option" disabled>Filter</option>
                    <optgroup label="Charity">
                        <option value="all">All</option>
                        {data.serviceProviders.map((serviceProvider, index) =>
                            <option key={index} value={serviceProvider.id}>{serviceProvider.organizationName}</option>
                        )}
                    </optgroup>
                </select>
            </>
        );
    }

    let itemsContent = data.loading
        ? <option>Loading...</option>
        : renderItems();
    let serviceProvidersDropdown = data.loading
        ? <option>Loading...</option>
        : renderServiceProviders();

    return (
        <div className="donate-items">
            <Row id="title-search">
                <Col lg="5" md="12" sm="12" xs="12"><h3 className="bluetitle">Item Donation</h3> <img className="amazonimg" src="../images/amazon.png" /></Col>
                <Col lg="3" md="6" sm="6" xs="6" className="sort-bar">
                    <select className="btn btn-dropdown dropdown-toggle" onChange={e => sortItems(e.currentTarget.value)} defaultValue={'DEFAULT'}>
                        <option className="hidden-option" value="DEFAULT" disabled>Sort</option>
                        <optgroup label="Alphabetical">
                            <option value="a-z">A-Z</option>
                            <option value="z-a">Z-A</option>
                        </optgroup>
                        <optgroup label="Price">
                            <option value="low-high">Low to High</option>
                            <option value="high-low">High to Low</option>
                        </optgroup>
                    </select>
                </Col>
                <Col lg="4" md="6" sm="6" xs="6" className="filter-bar">
                    {serviceProvidersDropdown}
                </Col>
            </Row>
           
            <hr />

            <Row id="pl">
                {itemsContent}
            </Row>
            <hr />
            <Row id="cta">
               
                <Col xl="12" lg="12" md="12" sm="12" xs="12" >
                    <h2 className="cantfind">Couldn't find anything within your budget?</h2>
                </Col>
                <Col xl="12" lg="12" md="12" sm="12" xs="12" >
                    <p>Every little bit counts!</p>
                </Col>
                <Col xl="12" lg="12" md="12" sm="12" xs="12" >
                    <p>You can donate what you can through our donation portal</p>
                </Col>
                <Col xl="12" lg="12" md="12" sm="12" xs="12" >
                    <a href="https://app.mobilecause.com/form/6dD6nA?utm_campaign=donation&amp;utm_medium=page&amp;utm_source=embed&amp;vid=ljbj3" target="_blank" className="btn btn-donate btn-lg">Donate Money</a>
                </Col>
            </Row>
        </div>
    );
}
export default DonateItems;
