import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, NavLink } from 'reactstrap';
import "../Items.css";

const RegisterModalmainnav = ({ handleClick }) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <>
            <NavLink onClick={toggle} className="text-dark hovercursor" >Register</NavLink>

            <Modal className="mymodalregister" isOpen={modal} centered={true} toggle={toggle}>
                <ModalHeader className="nobar" toggle={toggle}></ModalHeader>
                <ModalBody>
                    <div className="titleregister">Are you a:</div>
                    <div className="flexregister">
                        <div className="registerbuttonmodal">
                            <a href="/Identity/Account/RegisterUser" ><button type="button" className="btn btn-primary rbuttonmodal" >Donor</button></a>
                        </div>
                        <div className="registerbuttonmodal2">
                            <a href="/Identity/Account/Register" ><button type="button" className="btn btn-primary rbuttonmodal">Service Provider</button></a>
                        </div>
                    </div>
                   
                </ModalBody>
            </Modal>
        </>
    );
}

export default RegisterModalmainnav;