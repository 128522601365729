import React from 'react';

const Donate = () => {
    return (
        <div class="donate">
            <iframe scrolling="no" title="donateMoney" allowfullscreen="yes" frameBorder="0" src="https://app.mobilecause.com/form/6dD6nA?utm_campaign=donation&utm_medium=page&utm_source=embed&vid=5lc61"></iframe>
        </div>
    );
}

export default Donate;
