import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "../Items.css";

const AddItemModal = ({ itemName, handleClick }) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <>
            <Button color="success" onClick={toggle}>Add</Button>

            <Modal isOpen={modal} centered={true} toggle={toggle}>
                <ModalHeader toggle={toggle}>Confirm Add</ModalHeader>
                <ModalBody>
                    Are you sure you want to add: "<b>{itemName}</b>"?
                </ModalBody>
                <ModalFooter>
                    <div className="modalFooter">
                        <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                        <Button color="success" onClick={handleClick}>CONFIRM</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default AddItemModal;