import React, { Component, button }from 'react';
import { Button } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { prototype } from 'jspdf';




export class UpperReceipt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            address: "",
            date: "",
            desc: "",
            value: "",
            purchaseItemsId: "",
            email: "",
            buttonname: "Email Receipt",
            caption: "",
        };
    }

    componentDidMount() {
        const { name } = this.props.location.state
        const { address } = this.props.location.state
        const { date } = this.props.location.state
        const { desc } = this.props.location.state
        const { value } = this.props.location.state
        const { purchaseItemsId } = this.props.location.state
        const { email } = this.props.location.state

        this.setState({
            name: name,
            address: address,
            date: date,
            desc: desc,
            value: value,
            purchaseItemsId: purchaseItemsId,
            email: email,
        })
    }

    printReceipt() {
        window.print();
    }


    emailReceipt(purchaseItemsId, email, name, desc, value, thedate) {
        var d = new Date(thedate)
        var month = d.getMonth() + 1
        var day = d.getUTCDate()
        var year = d.getFullYear() 
       
        fetch('api/AspNetUsers/sendreceipt/' + purchaseItemsId + '/' + email + '/' + name + '/' + desc + '/' + value + '/' + month + '/' + day + '/' + year)

        this.setState({
            caption: "Receipt emailed to: " + email,
            buttonname: "Emailed Receipt"
        })
    }

    render() {
        
        return (
            <div>
                <Row>
                    <Col xs="0" sm="1" md="2" lg="3"></Col>
                    <Col xs="12" sm="10" md="8" lg="6" className="hide-on-print" style={{ fontSize: '1em', paddingBottom: '10px', textAlign: 'center', color: 'green' }}><strong>{this.state.caption }</strong></Col>
                    <Col xs="0" sm="1" md="2" lg="3"></Col>
                </Row>
                <Row>
                    <Col xs="1" sm="1" md="2" lg="3"></Col>
                    <Col xs="5" sm="5" md="4" lg="3">
                        <Button style={{ fontSize: '1em', paddingLeft: '5px', paddingRight: '5px' }} className="hide-on-print btn btn-primary" size="lg" block onClick={this.printReceipt}>Print Receipt</Button>
                    </Col>
                    <Col xs="5" sm="5" md="4" lg="3" >
                        <Button style={{ fontSize: '1em', paddingLeft: '5px', paddingRight: '5px'  }} className="hide-on-print btn btn-primary" size="lg" block onClick={() => this.emailReceipt(this.state.purchaseItemsId, this.state.email, this.state.name, this.state.desc, this.state.value, this.state.date)}>{this.state.buttonname}</Button>
                    </Col>
                    <Col xs="1" sm="1" md="2" lg="3"></Col>
                </Row>

                <br/>
                <div style={{ backgroundColor: "#FFFFFF", border: '2px solid black', paddingLeft: '30px', paddingRight: '30px', paddingTop: '10px'  }}>
                    <img style={{ minWidth: '120px', width: '30%', maxWidth: '200px' }} src="https://homeaidutahstorage.blob.core.windows.net/taxreceipt/homeaidutah.png" alt="Home Aid Logo" />
                    <p style={{ margin: "0px", fontSize: '.9em ', }}> 9069 South 1300 West </p>
                    <p style={{ margin: "0px", fontSize: '.9em', }}>West Jordan, UT 84088 </p>
                    <p style={{ margin: "0px", fontSize: '.9em', }}>(801) 556-4146   ׀   homeaidutah.org </p>
                    <br />
                    <h1 style={{ textAlign: 'center' }}><u> DONATION RECEIPT </u></h1>

                    <br />

                    <p> Dear, {this.state.name} </p>

                    <p> Thank you for your generous donation to HomeAid Utah. Your support is <i>invaluable</i>!</p>
                    <p> Date: <strong>{this.state.date}</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Item: <strong>{this.state.desc}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Value: <strong>${this.state.value}</strong> </p>
                    <p> Order ID for our reference is: {this.state.purchaseItemsId}</p>

                    <p>Your donation has filled a <i>specific</i> request from a provider of homeless services. Your donation is exactly what was needed at this time!</p>
                    <p>Working together we can <i>solve</i> homelessness.</p>
                    <p>Thank you again for your generosity!</p>
                    <br />
                    <p style={{ margin: "0px" }}>Best regards,</p>
                    <Row>
                        <Col xs="7" sm="5" md="4" lg="4" style={{ paddingLeft: '0px', paddingRight: '0', paddingTop:'15px'}}>
                            <img style={{ maxWidth: '300px', width:'100%', paddingRight: '0', paddingTop: '15px' }}  src="https://homeaidutahstorage.blob.core.windows.net/taxreceipt/sig.png" alt="Signature" /> 
                        </Col>
                        <Col style={{ paddingLeft: '0px', paddingRight: '0px', width: '0px' }} xs="0" sm="0" md="2" lg="2"></Col>
                        <Col  xs="5" sm="5" md="3" lg="3" style={{ paddingLeft: '30px', paddingRight: '0px' }}>
                            <img style={{ maxWidth: '180px', width: '100%' }} src="https://homeaidutahstorage.blob.core.windows.net/taxreceipt/scan.png" alt="Scan Me" />
                        </Col>
                    </Row>
                    
                    <br /> <br /> <br />
                    <p style={{ fontSize: '.8em' }}> This letter serves as a <strong>receipt of your donation</strong> to HomeAid Utah. Please note that no goods or services were provided in exchange for your contribution. HomeAid Utah is a 501(c)(3) non-profit organization, Federal Tax ID #83-2298965. We confirm that HomeAid Utah is eligible to receive tax-deductible contributions in accordance with Internal Revenue Code section 170. Please consult your tax advisor for charitable deductibility. </p>
                    <br />
                </div>
                <br />
                <div>
                </div>
             </div>
            
        );
    }
}