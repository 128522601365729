import React from 'react';
import footerimg from './images/HomeaidfooterLogo.png'
import hbalogo from './images/HBALogo.png'

const Footer = () => {
	return (
		
		<div className="footerrow hide-on-print">
			{/*
			<div className="container">
				<div className="row">

					<div className="col-md-5ths footerfirstcolumn">
						<a href="https://www.homeaidutah.org"><img src={footerimg} className="img-responsive" alt="HomeAid of Utah"/></a>
							<small>
								<span className="text-nowrap">P. 801-556-4146</span><br />
								<span className="text-nowrap">9069 S 1300 W</span> <br />
								<span className="text-nowrap">West Jordan, UT 84088</span>
							</small>				
					</div>


					<div className="col-md-5ths innerfootercolumn">
						<ul>
							<li><a href="https://www.homeaidutah.org/sponsors" className="gtm-sponsors">Sponsors</a></li>
							<li><a href="https://www.homeaidutah.org/projects" className="gtm-projects">Projects</a></li>
							<li><a href="https://www.homeaidutah.org/what-is-home-aid-utah" className="gtm-ourstory">Our Story</a></li>
							<li><a href="https://www.homeaidutah.org/news" className="gtm-news">News</a> & <a href="/events" className="gtm-events">Events</a></li>

						</ul>
					</div>

					<div className="col-md-5ths innerfootercolumn">
						<ul>

							<li><a href="https://www.homeaidutah.org/what-is-home-aid-utah" className="gtm-whatishomeaidutah">What is HomeAid?</a></li>
							<li><a href="https://www.homeaidutah.org/what-do-we-do" className="gtm-whatdowedo">What do we do?</a></li>
							<li><a href="https://www.homeaidutah.org/who-do-we-help" className="gtm-whodowehelp">Who do we help?</a></li>
							<li><a href="https://www.homeaidutah.org/how-can-i-help" className="gtm-howcanihelp">How can I help?</a></li>

						</ul>
					</div>

					<div className="col-md-5ths innerfootercolumn">
						<ul>

							<li><a href="https://www.homeaidutah.org/board-of-directors" className="gtm-boardofdirectors">Board of Directors</a></li>
							<li><a href="https://www.homeaidutah.org/financials" className="gtm-financials">Financials</a></li>

							<li><a href="https://www.homeaidutah.org/contact-us" className="gtm-contactus">Contact</a></li>
							<li><a href="https://www.homeaidutah.org/sitemap" className="gtm-sitemap">Sitemap</a></li>

						</ul>
					</div>

					<div className="col-md-5ths innerfootercolumn">
						<a href="http://www.slhba.com" target="_blank" rel="nofollow"><img src={hbalogo} className="img-responsive" alt="Home Builders Association of Salt Lake"/></a>
					</div>

					<div className="col-xs-6 innerfootercolumnsmall top-buffer bottom-buffer text-left">
						<ul>
							<li><a href="https://www.homeaidutah.org/sponsors" className="gtm-sponsors">Sponsors</a></li>
							<li><a href="https://www.homeaidutah.org/chapters" className="gtm-chapters">Chapters</a></li>
							<li><a href="https://www.homeaidutah.org/our-story" className="gtm-ourstory">Our Story</a></li>
							<li><a href="https://www.homeaidutah.org/news" className="gtm-news">News</a> & <a href="/events" className="gtm-events">Events</a></li>
							<li><a href="https://www.homeaidutah.org/celebrating-new-lives" className="gtm-celebratingnewlives">Celebrating New Lives</a></li>
							<li><a href="https://www.homeaidutah.org/financials" className="gtm-financials">Financials</a></li>
							<li><a href="https://www.homeaidutah.org/results-reporting" className="gtm-resultsreporting">Results Reporting</a></li>
						</ul>
					</div>

					<div className="col-xs-6 innerfootercolumnsmall top-buffer bottom-buffer text-left">
						<ul>
							<li><a href="https://www.homeaidutah.org/what-is-home-aid-utah" className="gtm-whatishomeaidutah">What is HomeAid?</a></li>
							<li><a href="https://www.homeaidutah.org/what-do-we-do" className="gtm-whatdowedo">What do we do?</a></li>
							<li><a href="https://www.homeaidutah.org/who-do-we-help" className="gtm-whodowehelp">Who do we help?</a></li>
							<li><a href="https://www.homeaidutah.org/how-can-i-help" className="gtm-howcanihelp">How can I help?</a></li>
							<li><a href="https://www.homeaidutah.org/contact-us" className="gtm-contactus">Contact</a></li>
							<li><a href="https://www.homeaidutah.org/our-partners" className="gtm-ourpartners">Our Partners</a></li>
							<li><a href="https://www.homeaidutah.org/board-of-directors" className="gtm-boardofdirectors">Board of Directors</a></li>
						</ul>
					</div>

				</div> 
			</div>

*/}
			<footer id="footer" className="font-small pt-3 pb-3 mt-2">
				<a className="nav_last">© Copyright 2020 Home Aid Utah, All Rights Reserved. </a><a href="/privacy" className="nav_last"> Policies & Privacy</a>
				<br />
				<a className="nav_last">HomeAid, Inc. is a 501(c)(3) corporation. Federal Non-Profit Tax ID No. 83-2298965.</a>
				<br />
				<a className="nav_last">As an Amazon Associate we earn from qualifying purchases.</a>
			</footer>
		
			</div>

    );
}

export default Footer;


//<ul>
//    <li className="pl-4 pr-4"><a href="/Conditions" className="nav_a">Conditions of Use</a></li>
//    <li className="pl-4 pr-4"><a href="/Privacy" className="nav_a">Privacy Notice</a></li>
//    <li className="pl-4 pr-4"><a href="/Help" className="nav_a">Help</a></li>
//</ul>