import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import "../Items.css";

const EditSPModal = ({ item, handleClick }) => {
    const [modal, setModal] = useState(false);
    const [disable, setDisable] = useState(false);

    const toggle = () => setModal(!modal);


    var formData = {
        "itemId": item.itemId,
        "organizationName": item.organizationName,
        "address": item.address,
        "city": item.city,
        "state": item.state,
        "zip": item.zip,
        "admin": item.admin,
        "email": item.email,
        "firstName": item.firstName,
        "id": item.id,
        "isServiceProvider": item.isServiceProvider,
        "lastName": item.lastName,
        "nonProfitEIN": item.nonProfitEIN,
        "organizationLogo": item.organizationLogo,
        "phoneNumber": item.phoneNumber,
        "taxDocument": item.taxDocument,
        "userName": item.userName,
    }



    function handleChange(event) {
        const property = event.target.name

        if (property === "organizationName") {
            formData[property] = event.target.value
            if (event.target.value === null) {
                setDisable(true);
            }
            else {
            }
        }
        else if (property === "address") {
            formData[property] = event.target.value
            if (event.target.value === null) {
                setDisable(true);
            }
            else { }

        }
        else if (property === "city") {
            formData[property] = event.target.value
            if (event.target.value === null) {
                setDisable(true);
            }
            else {
            }
        }
        else if (property === "state") {
            formData[property] = event.target.value
            if (event.target.value === null) {
                setDisable(true);
            }
            else {
            }
        }
        else if (property === "zip") {
            formData[property] = event.target.value
            if (event.target.value === null) {
                setDisable(true);
            }
            else {
            }
        }
        else {
            formData[property] = event.target.value

        }
    }

    function handleSubmit() {
        async function editItem() {
            const token = await authService.getAccessToken();
            //api/AspNetUsers/5
            await fetch('api/AspNetUsers/' + formData.id, {
                method: "PUT",
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
        }
        console.log(formData);
        editItem();
        handleClick();
        toggle();
    }

    return (

        <>
            <Button className="margin-right" color="secondary" onClick={toggle}>Edit</Button>
            
            <Modal isOpen={modal} centered={true} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    Editing: Service Provider
                </ModalHeader>
                <ModalBody style={{ paddingBottom: '10px' }}>
                    <FormGroup>
                        <Label for="organizationName">Name</Label>
                        <Input name="organizationName" id="organizationName" type="text" onChange={handleChange} required defaultValue={item.organizationName} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="address">Address</Label>
                        <Input name="address" id="address" type="text" onChange={handleChange} required defaultValue={item.address} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="city">City</Label>
                        <Input name="city" id="city" type="text" onChange={handleChange} required defaultValue={item.city} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="price">State</Label>
                        <br />
                        <select value={item.state} onChange={handleChange}>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                    </FormGroup>
                    <FormGroup>
                        <Label for="zip">Zip</Label>
                        <Input name="zip" id="zip" type="text" onChange={handleChange} required defaultValue={item.zip} />
                    </FormGroup>
                    <p style={{textAlign: "center", paddingBottom: '0px'}}><strong>After clicking submit please refresh the page to see your changes</strong></p>
                </ModalBody>
                <ModalFooter>
                    <div className="modalFooter">
                        <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                        <Button color="success" disabled={disable} onClick={handleSubmit}>Update</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default EditSPModal;