import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService';
import { Table, Row, Col } from 'reactstrap';
import "./Items.css";
import UpperReceipt from './UpperReceipt';

const Items = () => {
    const [data, setData] = useState({ items: [], loading: true, firstname: "", lastname:"", email:"" });

    // Get Items
    async function fetchData() {
        const token = await authService.getAccessToken();
        const authUser = await authService.getUser();
        const response = await fetch('api/PurchasedItems/userId/' + authUser.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        const token2 = await authService.getAccessToken();
        const authUser2 = await authService.getUser();
        const username = authUser2 && authUser2.name;
        const response2 = await fetch('api/AspNetUsers/littleinfo/' + username, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token2}` }
        });
        const persondata = await response2.json();

        data.sort((a, b) => (a.purchasedDate > b.purchasedDate) ? 1 : -1)
        setData({ items: data, loading: false, firstname: persondata.firstName, lastname:persondata.lastName, email:persondata.email });       
    };

    useEffect(() => {
        fetchData();
    }, []);

    const sortItems = (sortBy) => {
        if (sortBy === "newest-oldest") {
            setData({ items: data.items.sort((a, b) => (a.purchasedDate < b.purchasedDate) ? 1 : -1), loading: false });
        }
        else if (sortBy === "oldest-newest") {
            setData({ items: data.items.sort((a, b) => (a.purchasedDate > b.purchasedDate) ? 1 : -1), loading: false });
        }
        else if (sortBy === "a-z") {
            setData({ items: data.items.sort((a, b) => (a.requestedItem.productShortName > b.requestedItem.productShortName) ? 1 : -1), loading: false });
        }
        else if (sortBy === "z-a") {
            setData({ items: data.items.sort((a, b) => (a.requestedItem.productShortName < b.requestedItem.productShortName) ? 1 : -1), loading: false });
        }
        else if (sortBy === "low-high") {
            setData({ items: data.items.sort((a, b) => ((a.requestedItem.price * a.purchasedAmount) > (b.requestedItem.price * b.purchasedAmount)) ? 1 : -1), loading: false });
        }
        else if (sortBy === "high-low") {
            setData({ items: data.items.sort((a, b) => ((a.requestedItem.price * a.purchasedAmount) < (b.requestedItem.price * b.purchasedAmount)) ? 1 : -1), loading: false });
        }
    }

    const renderItemsTable = () => {
        return (
            <Table aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Order Number</th>
                        <th>Item</th>
                        <th>Charity</th>
                        <th>Order Total</th>
                        <th>Purchase Date</th>
                        <th>Receipt</th>
                    </tr>
                </thead>
                <tbody>
                    {data.items.map(item =>
                        <tr key={item.purchaseItemsId}>
                            <td>
                                {item.purchaseItemsId}
                            </td>
                            <td id="itemwidth" erg="true" >
                                <Row>
                                    <Col xs="12" sm="12" md="4" lg="4"> <img className="pic" border="0" src={'//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=' + item.requestedItem.itemImage + '&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=homeaidessent-20&language=en_US'}></img></Col>
                                    <Col xs="12" sm="12" md="8" lg="8">{item.requestedItem.productShortName}</Col>
                                </Row>
                            </td>
                            <td className="">
                                <img className="splogo" src={"https://homeaidutahstorage.blob.core.windows.net/organizationpics/" + item.organizationLogo} alt={item.organizationName} />
                            </td>
                            <td>
                                ${(item.requestedItem.price * item.purchasedAmount).toFixed(2)}
                            </td>
                            <td>
                                {item.purchasedDate.split("T")[0].split('-')[1]}/{item.purchasedDate.split("T")[0].split('-')[2]}/{item.purchasedDate.split("T")[0].split('-')[0]}
                            </td>
                            <td>
                                <Link to={{
                                    pathname: "/receipt",
                                    state: {
                                        name: data.firstname + " " + data.lastname,
                                        date: item.purchasedDate.split("T")[0].split('-')[1] + "/" + item.purchasedDate.split("T")[0].split('-')[2] + "/" + item.purchasedDate.split("T")[0].split('-')[0],
                                        desc: item.requestedItem.productShortName,
                                        value: (item.requestedItem.price * item.purchasedAmount).toFixed(2),
                                        purchaseItemsId: item.purchaseItemsId,
                                        email: data.email,
                                    }
                                }}>
                                    Receipt 
                                 </Link>
                                
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

    let contentTable = data.loading
        ? <p><em>Loading...</em></p>
        : renderItemsTable(data.items);

    return (
        <div>
            <Row>
                <Col lg="9" md="12" sm="12" xs="12"><h3 className="blue-title">My Orders</h3></Col>
                <Col lg="3" md="6" sm="6" xs="6" className="sort-bar">
                    <select className="btn btn-dropdown dropdown-toggle" onChange={e => sortItems(e.currentTarget.value)} defaultValue={'DEFAULT'}>
                        <option className="hidden-option" value="DEFAULT" disabled>Sort</option>
                        <optgroup label="Date">
                            <option value="newest-oldest">Newest to Oldest</option>
                            <option value="oldest-newest">Oldest to Newest</option>
                        </optgroup>
                        <optgroup label="Alphabetical">
                            <option value="a-z">A-Z</option>
                            <option value="z-a">Z-A</option>
                        </optgroup>
                        <optgroup label="Order Total">
                            <option value="low-high">Low to High</option>
                            <option value="high-low">High to Low</option>
                        </optgroup>
                    </select>
                </Col>
            </Row>
            {contentTable}
        </div>
    );
}

export default Items;
