import authService from '../api-authorization/AuthorizeService';

const DeleteItem = itemId => {
    async function fetchData() {
        const token = await authService.getAccessToken();
        await fetch('api/Items/EnableOrDisableItem/' + itemId, {
            method: "PUT",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
    }
    fetchData();
};

export default DeleteItem;
