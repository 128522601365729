import React, { } from 'react';
import { Route } from 'react-router';

import Layout from './components/Layout';
import DonateItems  from './components/DonateItems';
import { Privacy } from './components/Privacy';
//import { Login } from './components/api-authorization/Login'
import Donate from './components/Donate';
import Items from './components/Items';
import MyOrders from './components/MyOrders';
import Admin from './components/Admin';
import { UpperReceipt } from './components/UpperReceipt';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css'

const App = () => {
        return (
            <Layout>
                <Route exact path="/" component={DonateItems} />
                <Route path="/direct/:name" component={DonateItems} />
                <Route path='/privacy' component={Privacy} />
                <Route path='/donate' component={Donate} />
                <Route path='/receipt' component={UpperReceipt} />
                <AuthorizeRoute path='/items' component={Items} />
                <AuthorizeRoute path='/myorders' component={MyOrders} />
                <AuthorizeRoute path='/Admin' component={Admin} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        );
    }


export default App;


//export default class App extends Component {
//  static displayName = App.name;

//  render () {
//    return (
//       <Layout>
//        <Route exact path="/" component={DonateItems}/>
//        <Route path='/privacy' component={Privacy} />
//        <Route path='/donate' component={Donate} />
//        <AuthorizeRoute path='/items' component={Items} />
//        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
//      </Layout>
//    );
//  }
//}


//function loginAction(name) {
//    return (<Login action={name}></Login>);
//}

//<Route exact path='/' render={() => loginAction(LoginActions.Login)} />
//<AuthorizeRoute path='/donateitems' component={DonateItems} />

