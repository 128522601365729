import React, { Component, Fragment } from 'react';
import { NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Col} from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import RegisterModalmainnav from '../Modals/RegisterModalmainnav';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            isAdmin: false,
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        const username = user && user.name;
        const token = await authService.getAccessToken();
        const response = await fetch('api/AspNetUsers/littleinfo/' + username, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })

            .then(function (response) {
                return response.json()
            }).then(function (json) {
                return json
            }).catch(function (ex) {
                console.log('parsing failed', ex)
            })

        this.setState({
            isAuthenticated,
            userName: user && user.name,
            isServiceProvider: response.isServiceProvider,
            isAdmin: response.admin,
        });
    }

    render() {
        const { isAuthenticated, userName, isServiceProvider, isAdmin } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            if (isServiceProvider) {
                if (isAdmin) {
                    const itemsPath = `${ApplicationPaths.Items}`;
                    const profilePath = `${ApplicationPaths.Profile}`;
                    const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
                    return this.ServiceProviderAdminView(userName, itemsPath, profilePath, logoutPath);
                } else {
                    const itemsPath = `${ApplicationPaths.Items}`;
                    const profilePath = `${ApplicationPaths.Profile}`;
                    const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
                    return this.ServiceProviderView(userName, itemsPath, profilePath, logoutPath);
                }
            }
            else {
                if (isAdmin) {
                    const profilePath = `${ApplicationPaths.Profile}`;
                    const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
                    return this.authenticatedAdminView(userName, profilePath, logoutPath);
                }
                else {
                    const profilePath = `${ApplicationPaths.Profile}`;
                    const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
                    return this.authenticatedView(userName, profilePath, logoutPath);
                }
                
            }
        }
    }

    //ServiceProviderView(userName, itemsPath, profilePath, logoutPath) {
    //    return (<Fragment>
    //        <NavItem>
    //            <NavLink tag={Link} className="text-dark" to={itemsPath}>My Items</NavLink>
    //        </NavItem>
    //        <NavItem>
    //            <NavLink tag={Link} className="text-dark" to={profilePath}>My Account</NavLink>
    //        </NavItem>
    //        <NavItem>
    //            <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
    //        </NavItem>
    //    </Fragment>);
    //}

    //authenticatedView(userName, profilePath, logoutPath) {
    //    return (<Fragment>
    //        <NavItem>
    //            <NavLink tag={Link} className="text-dark" to={profilePath}>My Account</NavLink>
    //        </NavItem>
    //        <NavItem>
    //            <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
    //        </NavItem>
    //    </Fragment>);
    //}

    ServiceProviderView(userName, itemsPath, profilePath, logoutPath) {
        return (<Fragment>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    Account
                </DropdownToggle>
                <DropdownMenu left id="storydropdown">
                    <Col sm="12">
                        <NavLink tag={Link} className="text-dark" to={itemsPath}>My Items</NavLink>
                        <a className="text-dark nav-link" href="/myorders">My Orders</a>
                        <hr />
                        <NavLink tag={Link} className="text-dark" to={profilePath}>Account Details</NavLink>
                        <a className="text-dark nav-link" href="/Identity/Account/Manage/Email">Change Email</a>
                        <a className="text-dark nav-link" href="/Identity/Account/Manage/ChangePassword">Change Password</a>
                        <a className="text-dark nav-link" href="/Identity/Account/Manage/PersonalData">Delete Account</a>
                        <hr />
                        <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
                    </Col>
                </DropdownMenu>
            </UncontrolledDropdown>

        </Fragment >);
    }


    ServiceProviderAdminView(userName, itemsPath, profilePath, logoutPath) {
        return (<Fragment>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    Account
                </DropdownToggle>
                <DropdownMenu left="true" id="storydropdown">
                    <Col sm="12">
                        <NavLink tag={Link} className="text-dark" to={itemsPath}>My Items</NavLink>
                        <a className="text-dark nav-link" href="/myorders">My Orders</a>
                        <hr />
                        <a className="text-dark nav-link" href="/Admin">Admin</a>
                        <hr />
                        <NavLink tag={Link} className="text-dark" to={profilePath}>Account Details</NavLink>
                        <a className="text-dark nav-link" href="/Identity/Account/Manage/Email">Change Email</a>
                        <a className="text-dark nav-link" href="/Identity/Account/Manage/ChangePassword">Change Password</a>
                        <a className="text-dark nav-link" href="/Identity/Account/Manage/PersonalData">Delete Account</a>
                        <hr />
                        <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
                    </Col>
                </DropdownMenu>
            </UncontrolledDropdown>

        </Fragment >);
    }

    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    Account
                </DropdownToggle>
                <DropdownMenu left id="storydropdown">
                    <Col sm="12">
                        <a className="text-dark nav-link" href="/myorders">My Orders</a>
                        <hr />
                        <NavLink tag={Link} className="text-dark" to={profilePath}>Account Details</NavLink>
                        <a className="text-dark nav-link" href="/Identity/Account/Manage/Email">Change Email</a>
                        <a className="text-dark nav-link" href="/Identity/Account/Manage/ChangePassword">Change Password</a>
                        <a className="text-dark nav-link" href="/Identity/Account/Manage/PersonalData">Delete Account</a>
                        <hr />
                        <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
                    </Col>
                </DropdownMenu>
            </UncontrolledDropdown>
        </Fragment >);
    }

    authenticatedAdminView(userName, profilePath, logoutPath) {
        return (<Fragment>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    Account
                </DropdownToggle>
                <DropdownMenu left id="storydropdown">
                    <Col sm="12">
                        <a className="text-dark nav-link" href="/myorders">My Orders</a>
                        <hr />
                        <a className="text-dark nav-link" href="/Admin">Admin</a>
                        <hr />
                        <NavLink tag={Link} className="text-dark" to={profilePath}>Account Details</NavLink>
                        <a className="text-dark nav-link" href="/Identity/Account/Manage/Email">Change Email</a>
                        <a className="text-dark nav-link" href="/Identity/Account/Manage/ChangePassword">Change Password</a>
                        <a className="text-dark nav-link" href="/Identity/Account/Manage/PersonalData">Delete Account</a>
                        <hr />
                        <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
                    </Col>
                </DropdownMenu>
            </UncontrolledDropdown>
        </Fragment >);
    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    Account
                </DropdownToggle>
                <DropdownMenu left="true">
                    <Col sm="12">
                        <RegisterModalmainnav handleClick={() => { }} />
                        <NavLink tag={Link} to={loginPath}>Login</NavLink>
                    </Col>
                </DropdownMenu>
            </UncontrolledDropdown>
        </Fragment>);
    }


    //anonymousView(registerPath, loginPath) {
    //    return (<Fragment>
    //        <NavItem>
    //            <RegisterModalmainnav handleClick={() => { }} />
    //        </NavItem>
    //        <NavItem>
    //            <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>
    //        </NavItem>
    //    </Fragment>);
    //}
}
