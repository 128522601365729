import React, { useEffect, useState } from 'react';
import authService from './api-authorization/AuthorizeService';
import RegisterModalbottom from './Modals/RegisterModalbottom';

const BottomNav = () => {
    const [isAuth, setisAuth] = useState();
    const [isServiceP, setisServiceP] = useState();
    useEffect(() => {
        async function fetchData() {
            setisAuth(await authService.isAuthenticated());
            const token = await authService.getAccessToken();
            const user = await authService.getUser();
            const username = user && user.name;
            const response = await fetch('api/AspNetUsers/littleinfo/' + username, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(function (response) {
                    return response.json()
                }).then(function (json) {
                    setisServiceP(json.isServiceProvider)
                }).catch(function (ex) {
                    console.log('parsing failed', ex)
                })

        };
        fetchData();
    }, []);

    return (
        <div className="hide-on-print">
            <nav className="mobile-bottom-nav ">
                <div className="item item--active">
                    <div className="item-content">
                        <a href="/"><i className="fa fa-home fa-fw"></i><span className="brsmall">Home</span></a>
                    </div>
                </div>
                <div className="item">
                    <div className="item-content">
                        <a href="https://app.mobilecause.com/form/6dD6nA?utm_campaign=donation&utm_medium=page&utm_source=embed&vid=ljbj3" target="_blank"><i className="fa fa-donate fa-fw"></i><span className="brsmall">Donate</span></a>
                    </div>
                </div>
                <div className="item">
                    <div className="item-content">
                        {isServiceP === true &&
                            <a href="/Items"><i className="fa fa-list fa-fw"></i><span className="brsmall">Items</span></a>
                        }
                        {(isServiceP === false && isAuth === true) &&
                            <a href="/myorders"><i className="fa fa-list fa-fw"></i><span className="brsmall">Orders</span></a>
                        }
                        {isAuth === false &&
                            <RegisterModalbottom handleClick={() => { }} />
                        }
                    </div>
                </div>
                <div className="item">
                    <div className="item-content">
                        {isAuth === true &&
                            <a href="/Identity/Account/Manage/Index"><i className="fa fa-user fa-fw"></i><span className="brsmall"> Account</span></a>
                        }
                        {isAuth === false &&
                            <a href="/authentication/login"><i className="fa fa-user fa-fw"></i><span className="brsmall"> Login</span></a>
                        }
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default BottomNav;

//<NavItem>
//    <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
//</NavItem>
// <NavItem>
//    <NavLink tag={Link} className="text-dark" to="/items">Items</NavLink>
// </NavItem>
//