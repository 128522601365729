import React, { useState } from 'react';
import { Progress, Collapse, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form , FormGroup, Label, Col, Input } from 'reactstrap';
import "../Items.css";

const ConfirmPurchaseModal = ({ itemobj, allItems, user, authUserId, token }) => {
    const [modal, setModal] = useState(false);
    const [quantityReceived, setQuantityReceived] = useState(1);
    const [email, setEmail] = useState('');

    const toggle = () => setModal(!modal);

    const [isOpen, setIsOpen] = useState(true);
    const open = () => {
        setIsOpen(isOpen);
    }

    //console.log(itemobj)

    const updateAmount = (item, quantity) => {
        item.quantityReceived = parseFloat(item.quantityReceived) + parseFloat(quantity)

        if (item.quantityReceived === item.amountRequested) {
            const itemIndex = Object.keys(allItems).find(key => allItems[key] === item)
            allItems.splice(itemIndex, 1)
            //console.log(allItems)
        }

        // Get Items
        fetch('api/Items/' + item.itemId + '/' + item.quantityReceived, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            }
        });

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd

        if (authUserId == undefined) {
            authUserId = "0eebb274-d960-42ac-93e9-a034d69c2e77"
        }

        if (token.length < 1) {
            token = "eyJhbGciOiJSUzI1NiIsImtpZCI6IkRldmVsb3BtZW50IiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2MDQ5NjU0OTYsImV4cCI6MTYwNDk2OTA5NiwiaXNzIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NTAwMSIsImF1ZCI6ImhvbWVhaWRBUEkiLCJjbGllbnRfaWQiOiJob21lYWlkIiwic3ViIjoiM2U4ZmVkZjUtM2RmZC00ODMxLTk1NmQtMWY1OWJiMDdiZTQxIiwiYXV0aF90aW1lIjoxNjA0OTY1NDk0LCJpZHAiOiJsb2NhbCIsInNjb3BlIjpbIm9wZW5pZCIsInByb2ZpbGUiLCJob21lYWlkQVBJIl0sImFtciI6WyJwd2QiXX0.s2XnVkIiEtSIE8W0P9vMWCDewyIKwGkqATShP_4L2IXFAT8HeYt__67cVBnjs_dbqbqmJcTyMNmRz3F0g_NT8dj1 - pI1LE11FiUjaEhhhd2Ib75CneMWLyZPItmhlxgeVneOKmJDVjL_obnwLaTdBgDkgR76UlCgg2lxodW2OT9g0FOyER6q9MwLpJTp9mbLlPYhZJ1r0DmxsdBIeRguWI7H2bSjYvhxxt - fz9EU4xArhiS4KVeWvI6kdIp_119pXzrHbX1UsCT2hiU3 - PLLbB_t4uVzsf3NN7fveHRr6YfmFeYQz - QxgFpgN_lq7POqtUT_T87z - R9Uhp9CDVP5DQ"
        }


        var purchasedItemData = {
            "PurchasedUserId": authUserId,
            "PurchaseItemsId": item.itemId,
            "PurchasedAmount": quantity,
            "OrderTotal": item.price * quantity,
            "ReceiptLink": null,
        }

        console.log(purchasedItemData)

        const response = fetch('api/PurchasedItems/Create', {
            method: "POST",
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(purchasedItemData)
        });

        //if email is more than 1 character run send email 
        if (email.length > 0) {
            console.log("Email", email)
            var d = new Date();
            var month = d.getMonth() + 1;
            var day = d.getUTCDate();
            var year = d.getFullYear();

            var notLoggedInData = {
                "name": "Guest User",
                "email": email,
                "shortname": itemobj.productShortName,
                "value": purchasedItemData.OrderTotal,
                "month": month,
                "day": day,
                "year": year,
                "purchaseId": 'null',
            }

            var response2 = fetch('api/AspNetUsers/sendreceipt/' + notLoggedInData.purchaseId + '/' + notLoggedInData.email + '/' + notLoggedInData.name + '/' + notLoggedInData.shortname + '/' + notLoggedInData.value + '/' + notLoggedInData.month + '/' + notLoggedInData.day + '/' + notLoggedInData.year)
        } else {
            console.log("no email")
        }

        toggle()
    }

    function optionsnum() {
        var inum = [];

        for (var i = 1; i <= itemobj.amountRequested-itemobj.quantityReceived; i++) {
            inum.push(<option key={i} value={i}>{i}</option>)
        }
        return inum;
    }

    return (
        <>
            <Col xl="2" lg="3" md="3" sm="6" xs="6" className="col-xs-6" key={itemobj.itemName} onClick={toggle}>
                <div>
                    <div className="sp text-center"><img className="sppic" src={"https://homeaidutahstorage.blob.core.windows.net/organizationpics/" + user.organizationLogo} alt={user.organizationName} /></div>
                    <div className="text-center"><img className="pic" border="0"  src={`//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=${itemobj.itemImage}&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=itslivsrevi08-20&language=en_US`} ></img></div>
                    <div className="title text-center" style={{paddingTop: "25px", minHeight:"75px"}}>{itemobj.productShortName}</div>
                    <div className="price text-center" style={{paddingTop: "25px"}}>${itemobj.price.toFixed(2)}</div>
                    <p className="donatebartext" >{itemobj.quantityReceived} of {itemobj.amountRequested}</p>
                    <Progress color="haublue"  className="itemdonateprogress" value={itemobj.quantityReceived / itemobj.amountRequested * 100} />
                </div>
            </Col>


            <Modal isOpen={modal} centered={true} toggle={toggle}>
                <ModalHeader toggle={toggle}>Item Information</ModalHeader>
                <ModalBody>
                    <h5>Thank you for your willingness to help! Please follow these steps to make a donation:</h5>
                    <ul className="showBullet">
                        <li>Click <a target="_blank" rel="noopener noreferrer" href={itemobj.itemUrl} type="button" className="btn btn-amzNew" onClick={open}>Open item in Amazon</a> this will open the item in a separate tab. You're going to want to keep both tabs open.</li>
                        <li>In the Amazon tab, click "Buy Now" or "Add to Cart" and then "Proceed to Checkout".</li>
                        <li>In the shipping address section, please use the following address:</li>
                        <li className="hideBullet">
                            <div className="address">
                                <h5>{user.organizationName}</h5>
                                <h5>{user.address}</h5>
                                <h5>{user.city}, {user.state} {user.zip}</h5>
                            </div>
                        </li>
                        <li>Navigate back to this tab to update how many items you were able to contribute.</li>
                        {authUserId ?
                            <li className="hidden"></li>
                            :
                            <li>Type your email address to receive the receipt.</li>
                        }
                        <li>Confirm your donation. Thank you so much for your contribution!</li>
                    </ul>
        
                    <Collapse isOpen={isOpen} style={{ marginTop: '1rem' }}>
                        <div>
                            <Form>
                                <FormGroup row>
                                    <Label sm={12} className="mb-2">Thanks for purchasing <strong>{itemobj.productShortName}</strong>!</Label>
                                    <Label for="Amount Purchased" sm={8}>How many were you able to contribute? </Label>
                                    <Col sm={4}>
                                        <Input type="select" name="select" id="AmountSelect" onChange={e => setQuantityReceived(e.currentTarget.value)}>
                                            {optionsnum()}
                                        </Input>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </div>
                    </Collapse>
                    {authUserId ?
                        <h4></h4>
                        :
                        <div>
                            <h5>Oops! It seems like you aren't logged in.</h5>
                            <Input name="email" id="email" placeholder="Enter your email here for your receipt" onChange={t => setEmail(t.currentTarget.value)} required />
                        </div>

                    }
                </ModalBody>

                <Collapse isOpen={isOpen}>
                    <ModalFooter>
                        <div className="modalFooter">
                            <Button color="primary" onClick={() => updateAmount(itemobj, quantityReceived, email)}>Confirm</Button>{' '}
                            <Button color="secondary" onClick={toggle}>Cancel</Button>
                        </div>
                    </ModalFooter>
                </Collapse>
            </Modal>
        </>
    );


    //return (
    //    <>
    //        <Col xl="2" lg="3" md="3" sm="6" xs="6" className="col-xs-6" key={itemobj.itemName} onClick={toggle}>
    //            <div>
    //                <div className="sp text-center"><img className="sppic" src={"https://homeaidutahstorage.blob.core.windows.net/organizationpics/" + user.organizationLogo} alt={user.organizationName} /></div>
    //                <div className="text-center"><img className="pic" border="0"  src={`//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=${itemobj.itemImage}&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=itslivsrevi08-20&language=en_US`} ></img></div>
    //                <div className="title text-center" style={{paddingTop: "25px", minHeight:"75px"}}>{itemobj.productShortName}</div>
    //                <div className="price text-center" style={{paddingTop: "25px"}}>${itemobj.price.toFixed(2)}</div>
    //                <p className="donatebartext" >{itemobj.quantityReceived} of {itemobj.amountRequested}</p>
    //                <Progress color="haublue"  className="itemdonateprogress" value={itemobj.quantityReceived / itemobj.amountRequested * 100} />
    //            </div>
    //        </Col>


    //        <Modal isOpen={modal} centered={true} toggle={toggle}>
    //            <ModalHeader toggle={toggle}>Item Information</ModalHeader>
    //            <ModalBody>
    //                <h5>Please use the following name and address when checking out on Amazon:</h5>
    //                <div className="address">
    //                    <h4>{user.organizationName}</h4>
    //                    <h4>{user.address}</h4>
    //                    <h4>{user.city}, {user.state}</h4>
    //                    <h4>{user.zip}</h4>
    //                </div>
    //                <h6 className="center"><strong>PLEASE RECORD YOUR PURCHASE HERE AFTER CHECKING OUT ON AMAZON</strong></h6>
    //                <ModalFooter id="amazonbuttondiv">
    //                    <a target="_blank" rel="noopener noreferrer" href={itemobj.itemUrl} type="button" className="btn btn-amz" onClick={open}>Open item in Amazon</a>
    //                </ModalFooter>
    //                <Collapse isOpen={isOpen} style={{ marginTop: '1rem' }}>
    //                    <div>
    //                        <Form>
    //                            <FormGroup row>
    //                                <Label sm={12} className="mb-2">Thanks for purchasing <strong>{itemobj.productShortName}</strong>!</Label>
    //                                <Label for="Amount Purchased" sm={8}>How many were you able to contribute? </Label>
    //                                <Col sm={4}>
    //                                    <Input type="select" name="select" id="AmountSelect" onChange={e => setQuantityReceived(e.currentTarget.value)}>
    //                                        {optionsnum()}
    //                                    </Input>
    //                                </Col>
    //                            </FormGroup>
    //                        </Form>
    //                    </div>
    //                </Collapse>
    //                {authUserId ?
    //                    <h4></h4>
    //                    :
    //                    <div>
    //                        <h5>Opps! It seems like you aren't logged in.</h5>
    //                        <Input name="email" id="email" placeholder="Enter your email here for your receipt" onChange={t => setEmail(t.currentTarget.value)} required />
    //                    </div>

    //                }
    //            </ModalBody>

    //            <Collapse isOpen={isOpen}>
    //                <ModalFooter>
    //                    <div className="modalFooter">
    //                        <Button color="primary" onClick={() => updateAmount(itemobj, quantityReceived, email)}>Confirm</Button>{' '}
    //                        <Button color="secondary" onClick={toggle}>Cancel</Button>
    //                    </div>
    //                </ModalFooter>
    //            </Collapse>
    //        </Modal>
    //    </>
    //);
}

export default ConfirmPurchaseModal;

//                    <div className="text-center" dangerouslySetInnerHTML={{ __html: itemobj.itemImage }}></div>