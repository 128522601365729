import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import "../Items.css";

const DeleteSPModal = ({ item, handleClick }) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    function handleSubmit() {
        async function deleteItem() {
            const token = await authService.getAccessToken();
            //api/AspNetUsers/5
            await fetch('api/AspNetUsers/' + item.id, {
                method: "DELETE",
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
        }
        deleteItem();
        handleClick();
        toggle();
    }

    return (
        <>
            <Button color="danger" onClick={toggle}>Delete</Button>

            <Modal isOpen={modal} centered={true} toggle={toggle}>
                <ModalHeader toggle={toggle}>Confirm Delete</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete "<b>{item.organizationName}</b>"?
                </ModalBody>
                <ModalFooter>
                    <div className="modalFooter">
                        <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                        <Button color="danger" onClick={handleSubmit}>CONFIRM</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default DeleteSPModal;