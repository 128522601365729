import React, { useState, useEffect } from 'react';
import authService from './api-authorization/AuthorizeService';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Table, Progress } from 'reactstrap';
import classnames from 'classnames';
import DeleteItemModal from './Modals/DeleteItemModal';
import DeleteItem from './API Calls/DeleteItem';
import NewItemModal from './Modals/NewItemModal';
import EditItemModal from './Modals/EditItemModal';
import "./Items.css";
import CsvDownload from 'react-json-to-csv'

var allItems = []
var ALLpurchases = []
var purchaseCount

const Items = () => {
    const [activeTab, setActivateTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActivateTab(tab);
    }

    const [data, setData] = useState({ items: [], fitems: [], purchaseDetails: [], user: '', loading: true, isServiceProvider: 'false' });

    // Get Items
    async function fetchData() {
        const token = await authService.getAccessToken();
        const authUser = await authService.getUser();
        const response = await fetch('api/Items/userId/' + authUser.sub, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var unfulfilledItems = data.filter(item => (item.quantityReceived < item.amountRequested))
        unfulfilledItems = unfulfilledItems.filter(item => (item.active === true))
        unfulfilledItems.sort((a, b) => (a.productShortName.toLowerCase() > b.productShortName.toLowerCase()) ? 1 : -1)

        var fulfilledItems = data.filter(item => (item.quantityReceived >= item.amountRequested))
        fulfilledItems.sort((a, b) => (a.productShortName.toLowerCase() > b.productShortName.toLowerCase()) ? 1 : -1)

        const token2 = await authService.getAccessToken();
        const authUser2 = await authService.getUser();
        const username = authUser2 && authUser2.name;
        const response2 = await fetch('api/AspNetUsers/littleinfo/' + username, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token2}` }
        });
        const persondata = await response2.json();

        // Get Service Providers
        const usersResponse = await fetch('api/AspNetUsers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const usersData = await usersResponse.json();
        var serviceProviders = usersData

        //Get purchase details
        const response3 = await fetch('api/PurchasedItems/GetPurchasedDetails', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        let purchaseDetails = await response3.json();

        purchaseDetails = purchaseDetails.filter(item => (item.aspNetUSerID == authUser.sub))

        purchaseDetails.sort((a, b) => (a.purchasedDate < b.purchasedDate) ? 1 : -1)

        ALLpurchases = purchaseDetails

        purchaseCount = purchaseDetails.length

        purchaseDetails.forEach(function (element) {
            if (element.firstName == null) {
                element.id = serviceProviders[serviceProviders.findIndex(x => x.id === element.id)].organizationName
            } else {
                element.id = element.firstName + " " + element.lastName;
            }
            element.purchasedDate = element.purchasedDate.split("T")[0].split('-')[1] + "/" + element.purchasedDate.split("T")[0].split('-')[2] + "/" + element.purchasedDate.split("T")[0].split('-')[0];

            delete element.firstName; delete element.lastName; delete element.aspNetUSerID;
        })
        purchaseDetails = JSON.parse(JSON.stringify(purchaseDetails).split('"id":').join('"buyer":'));

        //console.log(persondata.isServiceProvider)

        setData({ items: unfulfilledItems, fitems: fulfilledItems, user: authUser.sub, purchaseDetails: purchaseDetails, loading: false, isServiceProvider: persondata.isServiceProvider });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const sortItems = (sortBy) => {
        if (sortBy === "newest-oldest") {
            setData({
                items: data.items.sort((a, b) => (a.dateRequested < b.dateRequested) ? 1 : -1),
                fitems: data.fitems.sort((a, b) => (a.dateRequested < b.dateRequested) ? 1 : -1),
                loading: false,
                isServiceProvider: data.isServiceProvider,
                user: data.user,
            });
        }
        else if (sortBy === "oldest-newest") {
            setData({
                items: data.items.sort((a, b) => (a.dateRequested > b.dateRequested) ? 1 : -1),
                fitems: data.fitems.sort((a, b) => (a.dateRequested > b.dateRequested) ? 1 : -1),
                loading: false,
                isServiceProvider: data.isServiceProvider,
                user: data.user,
            });
        }
        else if (sortBy === "a-z") {
            setData({
                items: data.items.sort((a, b) => (a.productShortName > b.productShortName) ? 1 : -1),
                fitems: data.fitems.sort((a, b) => (a.productShortName > b.productShortName) ? 1 : -1),
                loading: false,
                isServiceProvider: data.isServiceProvider,
                user: data.user,
            });
        }
        else if (sortBy === "z-a") {
            setData({
                items: data.items.sort((a, b) => (a.productShortName < b.productShortName) ? 1 : -1),
                fitems: data.fitems.sort((a, b) => (a.productShortName < b.productShortName) ? 1 : -1),
                loading: false,
                isServiceProvider: data.isServiceProvider,
                user: data.user,
            });
        }
        else if (sortBy === "ARlow-high") {
            setData({
                items: data.items.sort((a, b) => ((a.amountRequested) > (b.amountRequested)) ? 1 : -1),
                fitems: data.fitems.sort((a, b) => ((a.amountRequested) > (b.amountRequested)) ? 1 : -1),
                loading: false,
                isServiceProvider: data.isServiceProvider,
                user: data.user,
            });
        }
        else if (sortBy === "ARhigh-low") {
            setData({
                items: data.items.sort((a, b) => ((a.amountRequested) < (b.amountRequested)) ? 1 : -1),
                fitems: data.fitems.sort((a, b) => ((a.amountRequested) < (b.amountRequested)) ? 1 : -1),
                loading: false,
                isServiceProvider: data.isServiceProvider,
                user: data.user, });
        }
        else if (sortBy === "AFNlow-high") {
            setData({
                items: data.items.sort((a, b) => ((a.quantityReceived) > (b.quantityReceived)) ? 1 : -1),
                fitems: data.fitems.sort((a, b) => ((a.quantityReceived) > (b.quantityReceived)) ? 1 : -1),
                loading: false,
                isServiceProvider: data.isServiceProvider,
                user: data.user,
            });
        }
        else if (sortBy === "AFNhigh-low") {
            setData({
                items: data.items.sort((a, b) => ((a.quantityReceived) < (b.quantityReceived)) ? 1 : -1),
                fitems: data.fitems.sort((a, b) => ((a.quantityReceived) < (b.quantityReceived)) ? 1 : -1),
                loading: false,
                isServiceProvider: data.isServiceProvider,
                user: data.user,
            });
        }
        else if (sortBy === "AFPlow-high") {
            setData({
                items: data.items.sort((a, b) => (((a.quantityReceived / a.amountRequested) * 100) > (((b.quantityReceived / b.amountRequested) * 100))) ? 1 : -1),
                fitems: data.fitems.sort((a, b) => (((a.quantityReceived / a.amountRequested) * 100) > (((b.quantityReceived / b.amountRequested) * 100))) ? 1 : -1),
                loading: false,
                isServiceProvider: data.isServiceProvider,
                user: data.user,
            });
        }
        else if (sortBy === "AFPhigh-low") {
            setData({
                items: data.items.sort((a, b) => (((a.quantityReceived/a.amountRequested) * 100) < (((b.quantityReceived/b.amountRequested) * 100))) ? 1 : -1),
                fitems: data.fitems.sort((a, b) => (((a.quantityReceived / a.amountRequested) * 100) < (((b.quantityReceived / b.amountRequested) * 100))) ? 1 : -1),
                loading: false,
                isServiceProvider: data.isServiceProvider,
                user: data.user,
            });
        }
    }

    const filterItems = (filterValue) => {
        let filteredPurchases
        if (filterValue == "ALL") {
            filteredPurchases = ALLpurchases
        } else if (filterValue == "TODAY") {
            var d = new Date(); d.setDate(d.getDate());
            filteredPurchases = ALLpurchases.filter(i => new Date(i.purchasedDate) > d)
        } else if (filterValue == "7 DAYS") {
            var d = new Date(); d.setDate(d.getDate() - 7);
            filteredPurchases = ALLpurchases.filter(i => new Date(i.purchasedDate) > d)
        } else if (filterValue == "30 DAYS") {
            var d = new Date(); d.setDate(d.getDate() - 30);
            filteredPurchases = ALLpurchases.filter(i => new Date(i.purchasedDate) > d)
        } else {
            filteredPurchases = ALLpurchases
        }
        purchaseCount = filteredPurchases.length

        filteredPurchases = JSON.parse(JSON.stringify(filteredPurchases).split('"id":').join('"buyer":'));
        filteredPurchases = JSON.parse(JSON.stringify(filteredPurchases).split('"aspNetUSerID":').join('"serviceProvider":'));

        setData({ items: data.items, fitems: data.fitems, isServiceProvider: data.isServiceProvider, purchaseDetails: filteredPurchases, user: data.user, loading: false });
    }

    const getDataAfterDelete = index => {
        data.items.splice(index, 1)
        setData({ items: data.items, fitems: data.fitems, isServiceProvider: data.isServiceProvider, purchaseDetails: data.purchaseDetails, user: data.user, loading: false });
    }

    const renderItemsTable = () => {
        return (
            <Table aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Price</th>
                        <th>Amount Fulfilled</th>
                        <th>Date Requested</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data.items.map((item, index) =>
                        <tr key={item.itemName}>      
                            <td className="SP" id="itemwidth">
                                <Row>
                                    <Col xs="12" sm="12" md="4" lg="4"><img className="pic" border="0" src={'//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=' + item.itemImage + '&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=homeaidessent-20&language=en_US'}></img></Col>
                                    <Col xs="12" sm="12" md="8" lg="8">{item.productShortName}</Col>
                                </Row>
                            </td>
                            <td className="SP" id="pricewidth">${item.price.toFixed(2)}</td>
                            <td className="SP" id="amountfullwidth">
                                <div className="center-content">
                                    {item.quantityReceived} of {item.amountRequested}
                                    <Progress color="haublue" value={item.quantityReceived / item.amountRequested * 100} />
                                </div>
                            </td>
                            <td className="SP" id="daterequestedwidth">
                                {item.dateRequested.split("T")[0].split('-')[1]}/{item.dateRequested.split("T")[0].split('-')[2]}/{item.dateRequested.split("T")[0].split('-')[0]}
                            </td>
                            <td className="SP" id="actionwidth">
                                <div className="center-buttons">
                                    <EditItemModal item={item} handleClick={() => fetchData()} />
                                    <DeleteItemModal itemName={item.itemName} handleClick={() => { DeleteItem(item.itemId); getDataAfterDelete(index) }} />
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

   
    const renderItemsTable2 = () => {
            return (
                <Table aria-labelledby="tableLabel">
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Price</th>
                            <th>Amount Fulfilled</th>
                            <th>Date Requested</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.fitems.map(item =>
                            <tr key={item.itemName}>
                                <td className="SP" id="itemwidth">
                                    <Row>
                                        <Col xs="12" sm="12" md="4" lg="4"><img className="pic" border="0" src={'//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=' + item.itemImage + '&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=homeaidessent-20&language=en_US'}></img> </Col>
                                        <Col xs="12" sm="12" md="8" lg="8">{item.productShortName}</Col>
                                    </Row>
                                </td>
                                <td className="SP" id="pricewidth">${item.price.toFixed(2)}</td>
                                <td className="SP" id="amountfullwidth">
                                    <div className="center-content">
                                        {item.quantityReceived} of {item.amountRequested}
                                        <Progress color="haublue" value={item.quantityReceived / item.amountRequested * 100} />
                                    </div>
                                </td>
                                <td className="SP" id="daterequestedwidth">
                                    {item.dateRequested.split("T")[0].split('-')[1]}/{item.dateRequested.split("T")[0].split('-')[2]}/{item.dateRequested.split("T")[0].split('-')[0]}
                                </td>
                                <td className="SP" id="actionwidth">
                                    <div className="center-buttons">
                                        <EditItemModal item={item} handleClick={() => fetchData()} />
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            );
    }

    const renderItemsTable3 = () => {
        return (
            <Table aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Purchased Date</th>
                    </tr>
                </thead>
                <tbody>
                    {data.purchaseDetails.map((item, index) =>
                        <tr key={item.purchaseItemsId}>
                            <td className="AD2">
                                {item.buyer}
                            </td>
                            <td className="AD2">
                                {item.itemName}
                            </td>
                            <td className="AD2">
                                {item.amount} -- ${item.orderTotal.toFixed(2)}
                            </td>
                            <td className="AD2">
                                {item.purchasedDate}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

    let contentTable = data.loading
        ? <p><em>Loading...</em></p>
        : renderItemsTable(data.items);

    let contentTable2 = data.loading
        ? <p><em>Loading...</em></p>
        : renderItemsTable2(data.fitems);

    let contentTable3 = data.loading
        ? <p><em>Loading...</em></p>
        : renderItemsTable3();

    var isSP; 
    if (data.isServiceProvider) {
        isSP =
            <div>
            <Row>
                <Col xs="7" sm="8" md="4" lg="4">
                    <h3 id="tableLabel" className="blue-title">Item Portal</h3>
                </Col>
                <Col xs="0" sm="0"  md="4" lg="5" style={{ paddingLeft: '0px', paddingRight: '0px', width: '0px' }} >
                </Col>
                <Col xs="5" sm="4" md="4" lg="3">
                    <select className="btn btn-dropdown dropdown-toggle" onChange={e => sortItems(e.currentTarget.value)} defaultValue={'DEFAULT'}>
                        <option className="hidden-option" value="DEFAULT" disabled>Sort</option>
                         <optgroup label="Date">
                             <option value="newest-oldest">Newest to Oldest</option>
                             <option value="oldest-newest">Oldest to Newest</option>
                         </optgroup>
                         <optgroup label="Alphabetical">
                             <option value="a-z">A-Z</option>
                             <option value="z-a">Z-A</option>
                         </optgroup>
                         <optgroup label="Amount Requested">
                             <option value="ARlow-high">Low to High</option>
                             <option value="ARhigh-low">High to Low</option>
                         </optgroup>
                         <optgroup label="Amount Fulfilled (Number)">
                            <option value="AFNlow-high">Low to High</option>
                            <option value="AFNhigh-low">High to Low</option>
                         </optgroup>
                         <optgroup label="Amount Fulfilled (Percentage)">
                            <option value="AFPlow-high">Low to High</option>
                            <option value="AFPhigh-low">High to Low</option>
                         </optgroup>
                    </select>
                </Col>
            </Row>
            <Nav tabs>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                        Items Requested
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                        Items Fulfilled
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                        List of Recent Purchases
                    </NavLink>
                </NavItem>
                <NewItemModal user={data.user} handleClick={() => fetchData()} />
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <div>
                        {contentTable}
                    </div>
                </TabPane>
                <TabPane tabId="2">
                    <div>
                        {contentTable2}
                    </div>
                </TabPane>
                <TabPane tabId="3">
                    <div>
                        <Row>
                            <Col xs="0" sm="3" md="4" lg="4" > <CsvDownload data={data.purchaseDetails} filename="PurchaseData.csv"> Download Purchase Data</CsvDownload></Col>
                            <Col xs="6" sm="5" md="4" lg="4">
                                <select className="btn btn-dropdown dropdown-toggle" onChange={e => filterItems(e.currentTarget.value)} defaultValue={'ALL'}>
                                    <option className="hidden-option" value="DEFAULT" disabled>Sort</option>
                                    <optgroup label="Date">
                                        <option value="ALL">All</option>
                                        <option value="TODAY">Today</option>
                                        <option value="7 DAYS">Last 7 Days</option>
                                        <option value="30 DAYS">Last 30 Days</option>
                                    </optgroup>
                                </select>
                            </Col>
                            <Col xs="6" sm="4" md="4" lg="4" style={{ paddingTop: '5px' }}>Number of Orders: {purchaseCount}</Col>
                        </Row>
                        {contentTable3}
                    </div>
                </TabPane>
            </TabContent>
            </div>
    } else {
        isSP = <h1>You do not have access to this page.</h1>
    }

    return (
        <div>         
            {isSP}
        </div>
    );
}

export default Items;
