import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, DropdownMenu, UncontrolledDropdown, DropdownToggle, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';
import RegisterModalsidenav from './Modals/RegisterModalsidenav';
import logo from './images/HomeAid-Initiatives-essentials-full-rgb teal.png'


export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor (props) {
          super(props);

          this.toggleNavbar = this.toggleNavbar.bind(this);
          this.state = {
              collapsed: true,
              isAuthenticated: false,
              OrgName: "",
              FirstName: "",
              isServiceProvider: false,
              isAdmin: false,
          };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }
    async populateState() {
        const token = await authService.getAccessToken();
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        const username = user && user.name;
        const response = await fetch('api/AspNetUsers/littleinfo/' + username, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })

            .then(function (response) {
                return response.json()
            }).then(function (json) {
                return json
            }).catch(function (ex) {
                console.log('parsing failed', ex)
            })

        this.setState({
            isAuthenticated,
            OrgName: response.organizationName,
            FirstName: response.firstName,
            isServiceProvider: response.isServiceProvider,
            isAdmin: response.admin,
        });
    }

    toggleNavbar () {
          this.setState({
            collapsed: !this.state.collapsed
          });
    }

    sidefunction () {
        // Slide In Panel - by CodyHouse.co
        var panelTriggers = document.getElementsByClassName('js-cd-panel-trigger');
        if (panelTriggers.length > 0) {
            for (var i = 0; i < panelTriggers.length; i++) {
                (function (i) {
                    var panelClass = 'js-cd-panel-' + panelTriggers[i].getAttribute('data-panel'),
                        panel = document.getElementsByClassName(panelClass)[0];
                    // open panel when clicking on trigger btn
                    panelTriggers[i].addEventListener('click', function (event) {
                        event.preventDefault();
                        addClass(panel, 'cd-panel--is-visible');
                    });
                    //close panel when clicking on 'x' or outside the panel
                    panel.addEventListener('click', function (event) {
                        if (hasClass(event.target, 'js-cd-close') || hasClass(event.target, panelClass)) {
                            event.preventDefault();
                            removeClass(panel, 'cd-panel--is-visible');
                        }
                    });
                })(i);
            }
            //class manipulations - needed if classList is not supported
            //https://jaketrent.com/post/addremove-classes-raw-javascript/
            function hasClass(el, className) {
                if (el.classList) return el.classList.contains(className);
                else return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
            }
            function addClass(el, className) {
                if (el.classList) el.classList.add(className);
                else if (!hasClass(el, className)) el.className += " " + className;
            }
            function removeClass(el, className) {
                if (el.classList) el.classList.remove(className);
                else if (hasClass(el, className)) {
                    var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
                    el.className = el.className.replace(reg, ' ');
                }
            }
        }   
    };

    render() {
        const { isAuthenticated, OrgName, FirstName, isServiceProvider, isAdmin } = this.state;
        const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
        const loginPath = `${ApplicationPaths.Login}`;

        return (
            <header className="hide-on-print">
                <Navbar className="hsb navbar-expand-md navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 pb-0 pt-0 fixed-top myContainerHeader" light>
                    <Container className="pl-0 pr-0 nav-container myContainerHeader" >
                        <a href="https://www.homeaidutah.org" className="navbar-brand newLogo"><img className="nav-img2" src={logo} alt="Homeaid Essentials Logo" /></a>

                        <div className="secondarynav">
                            <div className="row">
                                <p className="center NeedHelp" ><strong>Need Help? </strong>Click here to view immediate online resources, as well as resources local to <strong>HomeAid Utah.</strong></p>
                            </div>
                        </div>


                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2 mt-1 col-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse navbarMax" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow" id="bignav" style={{ paddingRight: 80 + 'px' }}>

                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle id="dropdown1" nav className="text-dark nav-link" onMouseEnter={(e) => document.getElementById("dropdown1").click()} >
                                        About HomeAid
                                    </DropdownToggle>
                                    <DropdownMenu left="true" id="storydropdown" onMouseLeave={(e) => document.getElementById("dropdown1").click()}>
                                        <Col sm="12" md="12" lg="12">
                                            <a href="https://www.homeaidutah.org/about" className="gtm-whatishomeaidutah nav-link">About Us</a>
                                            <a href="https://www.homeaidutah.org/team" className="gtm-whatdowedo nav-link">Board & Staff</a>
                                            <a href="https://www.homeaidutah.org/news" className="gtm-whodowehelp nav-link">News & Process</a>
                                            
                                        </Col>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle id="dropdown2" nav className="text-dark nav-link" onMouseEnter={(e) => document.getElementById("dropdown2").click()} >
                                        Our Impact
                                    </DropdownToggle>
                                    <DropdownMenu left="true" id="storydropdown" onMouseLeave={(e) => document.getElementById("dropdown2").click()}>
                                        <Col sm="12" md="12" lg="12">
                                            <a href="https://www.homeaidutah.org/projects" className="gtm-whatishomeaidutah nav-link">Projects</a>
                                            <a href="https://www.homeaidutah.org/community" className="gtm-whatdowedo nav-link">Community Engagement</a>
                                            <a href="https://www.homeaidutah.org/education" className="gtm-whodowehelp nav-link">Education</a>

                                        </Col>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle id="dropdown3" nav className="text-dark nav-link" onMouseEnter={(e) => document.getElementById("dropdown3").click()} >
                                        Get Involved
                                    </DropdownToggle>
                                    <DropdownMenu left="true" id="storydropdown" onMouseLeave={(e) => document.getElementById("dropdown3").click()}>
                                        <Col sm="12" md="12" lg="12">
                                            <a href="https://www.homeaidutah.org/volunteer" className="gtm-whatishomeaidutah nav-link">Volunteer</a>
                                            <a href="https://www.homeaidutah.org/give" className="gtm-whatdowedo nav-link">Ways To Give</a>
                                            <a href="https://www.homeaidutah.org/events" className="gtm-whodowehelp nav-link">Events</a>
                                            <a href="" className="gtm-howcanihelp nav-link">Donate Items</a>
                                        </Col>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle id="dropdown4" nav className="text-dark nav-link" onMouseEnter={(e) => document.getElementById("dropdown4").click()} onMouseLeave={(e) => document.getElementById("dropdown4").click()}>
                                        Partners
                                    </DropdownToggle>
                                    <DropdownMenu left="true" id="storydropdown">
                                        <Col sm="12" md="12" lg="12">
                                            <a href="https://www.homeaidutah.org/builder-captains" className="gtm-whatishomeaidutah nav-link">Builder Captains</a>
                                            <a href="https://www.homeaidutah.org/trade-partners" className="gtm-whatdowedo nav-link">Trade Partners</a>
                                            <a href="https://www.homeaidutah.org/corporate" className="gtm-whodowehelp nav-link">Corporate & Foundations</a>
                                            <a href="https://www.homeaidutah.org/nonprofit-partners" className="gtm-howcanihelp nav-link">Non-Profit Partners</a>
                                        </Col>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle id="dropdown5" nav className="text-dark nav-link" onMouseEnter={(e) => document.getElementById("dropdown5").click()} onMouseLeave={(e) => document.getElementById("dropdown5").click()}>
                                        Resources
                                    </DropdownToggle>
                                    <DropdownMenu left="true" id="storydropdown">
                                        <Col sm="12" md="12" lg="12">
                                            <a href="https://www.homeaidutah.org/help" className="gtm-whatishomeaidutah nav-link">Need Help?</a>
                                            <a href="https://www.homeaidutah.org/financials" className="gtm-whatdowedo nav-link">Financials?</a>
                                        </Col>
                                    </DropdownMenu>
                                </UncontrolledDropdown>


                             

                                <li><a href="https://www.homeaidutah.org/contact-us" className="gtm-contactus text-dark nav-link ">Contact</a></li>

                                <LoginMenu>
                                </LoginMenu>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>

                <Navbar className="displayes navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 fixed-top" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/"><img className="nav-img" src={logo} alt="Homeaid Essentials Logo" /></NavbarBrand>
                        <button id="navbartog" onClick={this.sidefunction} type="button" className="mr-2 mt-1 col-2 navbar-toggler js-cd-panel-trigger" data-panel="main"><span className="navbar-toggler-icon"></span></button>

                        <div className="cd-panel cd-panel--from-right js-cd-panel-main" id="mySidebar">
                            <header className="cd-panel__header">
                                {!isAuthenticated ? <h3>Welcome!</h3> : ( OrgName?<h3>{ OrgName }</h3> : <h3>Hi {FirstName}!</h3>) }
                                   
                                <a href="#0" className="cd-panel__close js-cd-close">Close</a>
                            </header>
                            <div className="cd-panel__container">
                                <div className="cd-panel__content">
                                    {(() => {
                                        if (!isAuthenticated) {
                                            return (
                                                <ul className="navbar-nav flex-grow">
                                                    <NavItem>
                                                        <RegisterModalsidenav handleClick={() => { }} />
                                                        <a href={loginPath} className="w3-bar-item sn-button col-12" >Login</a>
                                                    </NavItem>
                                                </ul>
                                            )
                                        }
                                        else {
                                            if (isAdmin) {
                                                if (isServiceProvider) {
                                                    return (
                                                        <>
                                                            <a href="/Admin" style={{ paddingTop: 10 + 'px' }} className="w3-bar-item sn-button col-12">Admin</a>
                                                            <a href="/myorders"  className="w3-bar-item sn-button col-12">My Orders</a>
                                                            <a href="/Identity/Account/Manage/Index" className="w3-bar-item sn-button col-12">Account Details</a>
                                                            <a href="/Identity/Account/Manage/Email" className="w3-bar-item sn-button col-12">Change Email</a>
                                                            <a href="/Identity/Account/Manage/ChangePassword" className="w3-bar-item sn-button col-12">Change Password</a>
                                                            <a href="/Identity/Account/Manage/PersonalData" className="w3-bar-item sn-button col-12">Delete Account</a>
                                                            <NavItem>
                                                                <NavLink tag={Link} className="w3-bar-item sn-button col-12" to={logoutPath}>Logout</NavLink>
                                                            </NavItem>
                                                        </>
                                                    )
                                                } else {
                                                    return (
                                                        <>
                                                            <a href="/Admin" style={{ paddingTop: 10 + 'px' }} className="w3-bar-item sn-button col-12">Admin</a>
                                                            <a href="/Identity/Account/Manage/Index" className="w3-bar-item sn-button col-12">Account Details</a>
                                                            <a href="/Identity/Account/Manage/Email" className="w3-bar-item sn-button col-12">Change Email</a>
                                                            <a href="/Identity/Account/Manage/ChangePassword" className="w3-bar-item sn-button col-12">Change Password</a>
                                                            <a href="/Identity/Account/Manage/PersonalData" className="w3-bar-item sn-button col-12">Delete Account</a>
                                                            <NavItem>
                                                                <NavLink tag={Link} className="w3-bar-item sn-button col-12" to={logoutPath}>Logout</NavLink>
                                                            </NavItem>
                                                        </>
                                                    )
                                                }      
                                            } else {
                                                if (isServiceProvider) {
                                                    return (
                                                        <>
                                                            <a href="/myorders" className="w3-bar-item sn-button col-12">My Orders</a>
                                                            <a href="/Identity/Account/Manage/Index" className="w3-bar-item sn-button col-12">Account Details</a>
                                                            <a href="/Identity/Account/Manage/Email" className="w3-bar-item sn-button col-12">Change Email</a>
                                                            <a href="/Identity/Account/Manage/ChangePassword" className="w3-bar-item sn-button col-12">Change Password</a>
                                                            <a href="/Identity/Account/Manage/PersonalData" className="w3-bar-item sn-button col-12">Delete Account</a>
                                                            <NavItem>
                                                                <NavLink tag={Link} className="w3-bar-item sn-button col-12" to={logoutPath}>Logout</NavLink>
                                                            </NavItem>
                                                        </>
                                                    )
                                                } else {
                                                    return (
                                                        <>
                                                            <a href="/Identity/Account/Manage/Index" className="w3-bar-item sn-button col-12" style={{ paddingTop: 10 + 'px !important' }}>Account Details</a>
                                                            <a href="/Identity/Account/Manage/Email" className="w3-bar-item sn-button col-12">Change Email</a>
                                                            <a href="/Identity/Account/Manage/ChangePassword" className="w3-bar-item sn-button col-12">Change Password</a>
                                                            <a href="/Identity/Account/Manage/PersonalData" className="w3-bar-item sn-button col-12">Delete Account</a>
                                                            <NavItem>
                                                                <NavLink tag={Link} className="w3-bar-item sn-button col-12" to={logoutPath}>Logout</NavLink>
                                                            </NavItem>
                                                        </>
                                                    )
                                                }
                                            }
                                        }
                                    
                                    })()}
                                </div>
                            </div>
                        </div>
                    </Container>
                </Navbar>
            </header>
            );
    }
    
}

//return (
//    <header className="hide-on-print">
//        <Navbar className="hsb navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 pb-0 pt-0 fixed-top myContainerHeader" light>
//            <Container className="pl-0 pr-0 nav-container myContainerHeader" >
//                <a href="https://www.homeaidutah.org" className="navbar-brand newLogo"><img className="nav-img2" src="//images.squarespace-cdn.com/content/v1/61280966b9f95f36f0c60930/7fa4e358-d061-448d-83fa-e9dc4c36a550/HomeAid-Utah-full-rgb.png?format=1500w" alt="Homeaid Essentials Logo" /></a>

//                <div className="secondarynav">
//                    <div className="row">
//                        <p className="center NeedHelp" ><strong>Need Help? </strong>Click here to view immediate online resources, as well as resources local to <strong>HomeAid Utah.</strong></p>
//                    </div>
//                </div>


//                <NavbarToggler onClick={this.toggleNavbar} className="mr-2 mt-1 col-2" />
//                <Collapse className="d-sm-inline-flex flex-sm-row-reverse navbarMax" isOpen={!this.state.collapsed} navbar>
//                    <ul className="navbar-nav flex-grow" id="bignav" style={{ paddingRight: 60 + 'px' }}>

//                        <UncontrolledDropdown nav inNavbar>
//                            <DropdownToggle id="dropdown1" nav className="text-dark nav-link" onMouseEnter={(e) => document.getElementById("dropdown1").click()} onMouseLeave={(e) => document.getElementById("dropdown1").click()}>
//                                About HomeAid
//                                    </DropdownToggle>
//                            <DropdownMenu left="true" id="storydropdown">
//                                <Col sm="12" md="12" lg="12">
//                                    <a href="https://www.homeaidutah.org/what-is-home-aid-utah" className="gtm-whatishomeaidutah nav-link">What is HomeAid Utah?</a>
//                                    <a href="https://www.homeaidutah.org/what-do-we-do" className="gtm-whatdowedo nav-link">What do we do?</a>
//                                    <a href="https://www.homeaidutah.org/who-do-we-help" className="gtm-whodowehelp nav-link">Who do we help?</a>
//                                    <a href="https://www.homeaidutah.org/how-can-i-help" className="gtm-howcanihelp nav-link">How can I help?</a>
//                                    <a href="https://www.homeaidutah.org/board-of-directors" className="gtm-board-of-directors nav-link">Board of Directors</a>
//                                </Col>
//                            </DropdownMenu>
//                        </UncontrolledDropdown>

//                        <UncontrolledDropdown nav inNavbar>
//                            <DropdownToggle id="dropdown2" nav className="text-dark nav-link" onMouseEnter={(e) => document.getElementById("dropdown2").click()} onMouseLeave={(e) => document.getElementById("dropdown2").click()}>
//                                About HomeAid
//                                    </DropdownToggle>
//                            <DropdownMenu left="true" id="storydropdown">
//                                <Col sm="12" md="12" lg="12">
//                                    <a href="https://www.homeaidutah.org/what-is-home-aid-utah" className="gtm-whatishomeaidutah nav-link">What is HomeAid Utah?</a>
//                                    <a href="https://www.homeaidutah.org/what-do-we-do" className="gtm-whatdowedo nav-link">What do we do?</a>
//                                    <a href="https://www.homeaidutah.org/who-do-we-help" className="gtm-whodowehelp nav-link">Who do we help?</a>
//                                    <a href="https://www.homeaidutah.org/how-can-i-help" className="gtm-howcanihelp nav-link">How can I help?</a>
//                                    <a href="https://www.homeaidutah.org/board-of-directors" className="gtm-board-of-directors nav-link">Board of Directors</a>
//                                </Col>
//                            </DropdownMenu>
//                        </UncontrolledDropdown>

//                        <UncontrolledDropdown nav inNavbar>
//                            <DropdownToggle id="dropdown3" nav className="text-dark nav-link" onMouseEnter={(e) => document.getElementById("dropdown3").click()} onMouseLeave={(e) => document.getElementById("dropdown3").click()}>
//                                About HomeAid
//                                    </DropdownToggle>
//                            <DropdownMenu left="true" id="storydropdown">
//                                <Col sm="12" md="12" lg="12">
//                                    <a href="https://www.homeaidutah.org/what-is-home-aid-utah" className="gtm-whatishomeaidutah nav-link">What is HomeAid Utah?</a>
//                                    <a href="https://www.homeaidutah.org/what-do-we-do" className="gtm-whatdowedo nav-link">What do we do?</a>
//                                    <a href="https://www.homeaidutah.org/who-do-we-help" className="gtm-whodowehelp nav-link">Who do we help?</a>
//                                    <a href="https://www.homeaidutah.org/how-can-i-help" className="gtm-howcanihelp nav-link">How can I help?</a>
//                                    <a href="https://www.homeaidutah.org/board-of-directors" className="gtm-board-of-directors nav-link">Board of Directors</a>
//                                </Col>
//                            </DropdownMenu>
//                        </UncontrolledDropdown>

//                        <UncontrolledDropdown nav inNavbar>
//                            <DropdownToggle id="dropdown4" nav className="text-dark nav-link" onMouseEnter={(e) => document.getElementById("dropdown4").click()} onMouseLeave={(e) => document.getElementById("dropdown4").click()}>
//                                About HomeAid
//                                    </DropdownToggle>
//                            <DropdownMenu left="true" id="storydropdown">
//                                <Col sm="12" md="12" lg="12">
//                                    <a href="https://www.homeaidutah.org/what-is-home-aid-utah" className="gtm-whatishomeaidutah nav-link">What is HomeAid Utah?</a>
//                                    <a href="https://www.homeaidutah.org/what-do-we-do" className="gtm-whatdowedo nav-link">What do we do?</a>
//                                    <a href="https://www.homeaidutah.org/who-do-we-help" className="gtm-whodowehelp nav-link">Who do we help?</a>
//                                    <a href="https://www.homeaidutah.org/how-can-i-help" className="gtm-howcanihelp nav-link">How can I help?</a>
//                                    <a href="https://www.homeaidutah.org/board-of-directors" className="gtm-board-of-directors nav-link">Board of Directors</a>
//                                </Col>
//                            </DropdownMenu>
//                        </UncontrolledDropdown>

//                        <UncontrolledDropdown nav inNavbar>
//                            <DropdownToggle id="dropdown5" nav className="text-dark nav-link" onMouseEnter={(e) => document.getElementById("dropdown5").click()} onMouseLeave={(e) => document.getElementById("dropdown5").click()}>
//                                About HomeAid
//                                    </DropdownToggle>
//                            <DropdownMenu left="true" id="storydropdown">
//                                <Col sm="12" md="12" lg="12">
//                                    <a href="https://www.homeaidutah.org/what-is-home-aid-utah" className="gtm-whatishomeaidutah nav-link">What is HomeAid Utah?</a>
//                                    <a href="https://www.homeaidutah.org/what-do-we-do" className="gtm-whatdowedo nav-link">What do we do?</a>
//                                    <a href="https://www.homeaidutah.org/who-do-we-help" className="gtm-whodowehelp nav-link">Who do we help?</a>
//                                    <a href="https://www.homeaidutah.org/how-can-i-help" className="gtm-howcanihelp nav-link">How can I help?</a>
//                                    <a href="https://www.homeaidutah.org/board-of-directors" className="gtm-board-of-directors nav-link">Board of Directors</a>
//                                </Col>
//                            </DropdownMenu>
//                        </UncontrolledDropdown>




//                        <li><a href="https://www.homeaidutah.org/contact-us" className="gtm-contactus text-dark nav-link ">Contact</a></li>

//                        <LoginMenu>
//                        </LoginMenu>
//                    </ul>
//                </Collapse>
//            </Container>
//        </Navbar>

//        <Navbar className="displayes navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 fixed-top" light>
//            <Container>
//                <NavbarBrand tag={Link} to="/"><img className="nav-img" src="../images/essentials logo blue.png" alt="Homeaid Essentials Logo" /></NavbarBrand>
//                <button id="navbartog" onClick={this.sidefunction} type="button" className="mr-2 mt-1 col-2 navbar-toggler js-cd-panel-trigger" data-panel="main"><span className="navbar-toggler-icon"></span></button>

//                <div className="cd-panel cd-panel--from-right js-cd-panel-main" id="mySidebar">
//                    <header className="cd-panel__header">
//                        {!isAuthenticated ? <h3>Welcome!</h3> : (OrgName ? <h3>{OrgName}</h3> : <h3>Hi {FirstName}!</h3>)}

//                        <a href="#0" className="cd-panel__close js-cd-close">Close</a>
//                    </header>
//                    <div className="cd-panel__container">
//                        <div className="cd-panel__content">
//                            {(() => {
//                                if (!isAuthenticated) {
//                                    return (
//                                        <ul className="navbar-nav flex-grow">
//                                            <NavItem>
//                                                <RegisterModalsidenav handleClick={() => { }} />
//                                                <a href={loginPath} className="w3-bar-item sn-button col-12" >Login</a>
//                                            </NavItem>
//                                        </ul>
//                                    )
//                                }
//                                else {
//                                    if (isAdmin) {
//                                        if (isServiceProvider) {
//                                            return (
//                                                <>
//                                                    <a href="/Admin" style={{ paddingTop: 10 + 'px' }} className="w3-bar-item sn-button col-12">Admin</a>
//                                                    <a href="/myorders" className="w3-bar-item sn-button col-12">My Orders</a>
//                                                    <a href="/Identity/Account/Manage/Index" className="w3-bar-item sn-button col-12">Account Details</a>
//                                                    <a href="/Identity/Account/Manage/Email" className="w3-bar-item sn-button col-12">Change Email</a>
//                                                    <a href="/Identity/Account/Manage/ChangePassword" className="w3-bar-item sn-button col-12">Change Password</a>
//                                                    <a href="/Identity/Account/Manage/PersonalData" className="w3-bar-item sn-button col-12">Delete Account</a>
//                                                    <NavItem>
//                                                        <NavLink tag={Link} className="w3-bar-item sn-button col-12" to={logoutPath}>Logout</NavLink>
//                                                    </NavItem>
//                                                </>
//                                            )
//                                        } else {
//                                            return (
//                                                <>
//                                                    <a href="/Admin" style={{ paddingTop: 10 + 'px' }} className="w3-bar-item sn-button col-12">Admin</a>
//                                                    <a href="/Identity/Account/Manage/Index" className="w3-bar-item sn-button col-12">Account Details</a>
//                                                    <a href="/Identity/Account/Manage/Email" className="w3-bar-item sn-button col-12">Change Email</a>
//                                                    <a href="/Identity/Account/Manage/ChangePassword" className="w3-bar-item sn-button col-12">Change Password</a>
//                                                    <a href="/Identity/Account/Manage/PersonalData" className="w3-bar-item sn-button col-12">Delete Account</a>
//                                                    <NavItem>
//                                                        <NavLink tag={Link} className="w3-bar-item sn-button col-12" to={logoutPath}>Logout</NavLink>
//                                                    </NavItem>
//                                                </>
//                                            )
//                                        }
//                                    } else {
//                                        if (isServiceProvider) {
//                                            return (
//                                                <>
//                                                    <a href="/myorders" className="w3-bar-item sn-button col-12">My Orders</a>
//                                                    <a href="/Identity/Account/Manage/Index" className="w3-bar-item sn-button col-12">Account Details</a>
//                                                    <a href="/Identity/Account/Manage/Email" className="w3-bar-item sn-button col-12">Change Email</a>
//                                                    <a href="/Identity/Account/Manage/ChangePassword" className="w3-bar-item sn-button col-12">Change Password</a>
//                                                    <a href="/Identity/Account/Manage/PersonalData" className="w3-bar-item sn-button col-12">Delete Account</a>
//                                                    <NavItem>
//                                                        <NavLink tag={Link} className="w3-bar-item sn-button col-12" to={logoutPath}>Logout</NavLink>
//                                                    </NavItem>
//                                                </>
//                                            )
//                                        } else {
//                                            return (
//                                                <>
//                                                    <a href="/Identity/Account/Manage/Index" className="w3-bar-item sn-button col-12" style={{ paddingTop: 10 + 'px !important' }}>Account Details</a>
//                                                    <a href="/Identity/Account/Manage/Email" className="w3-bar-item sn-button col-12">Change Email</a>
//                                                    <a href="/Identity/Account/Manage/ChangePassword" className="w3-bar-item sn-button col-12">Change Password</a>
//                                                    <a href="/Identity/Account/Manage/PersonalData" className="w3-bar-item sn-button col-12">Delete Account</a>
//                                                    <NavItem>
//                                                        <NavLink tag={Link} className="w3-bar-item sn-button col-12" to={logoutPath}>Logout</NavLink>
//                                                    </NavItem>
//                                                </>
//                                            )
//                                        }
//                                    }
//                                }

//                            })()}
//                        </div>
//                    </div>
//                </div>
//            </Container>
//        </Navbar>
//    </header>
//);

//<NavItem>
//    <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
//</NavItem>
// <NavItem>
//    <NavLink tag={Link} className="text-dark" to="/items">Items</NavLink>
// </NavItem>
//