import React from 'react';
import { Route } from 'react-router';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import BottomNav from './BottomNav';
//import authService from './api-authorization/AuthorizeService';
import Footer from './Footer';
// import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
// import { Link } from 'react-router-dom';
// import { NavLink } from 'reactstrap';

const Layout = (props) => {  
    return (
        <div id="wrapper">
            <Route path="/" component={NavMenu} />

            <Container>
                <div id="content">
                    {props.children}
                </div>
            </Container>
            <Footer />


            <Route path="/" component={BottomNav} />
        </div>
    );
}

export default Layout;



//export class Layout extends Component {
//    static displayName = Layout.name;


//    constructor(props) {
//        super(props);

//        this.state = {
//            isAuthenticated2: false,
//            data: false,
//        };
//    }

//    componentDidMount() {
//        this._subscription = authService.subscribe(() => this.populateState());
//        this.populateState();
//    }

//    componentDidUpdate() {
//        this._subscription = authService.subscribe(() => this.populateState());
//        this.populateState();
//    }

//    componentWillUnmount() {
//        authService.unsubscribe(this._subscription);
//    }

//    async componentWillMount() {
//        const [isAuthenticated2, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])

//        console.log("before if statement: Logged in: " + isAuthenticated2)
//        if (isAuthenticated2) {
//            console.log("hit the get user")
//            const token = await authService.getAccessToken();
//            const username = user && user.name;
//            const userResponse = await window.fetch('api/AspNetUsers/user/' + username, {
//                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
//            });

//            const usersData = await userResponse.json();
//            console.log(usersData)
//            this.setState({
//                isAuthenticated2,
//                data: usersData.isServiceProvider,
//                //isAuthenticated2: isAuthenticated2
//            });
//        } else {
//            console.log("did not hit the get user")
//        }
//    }

//    async populateState() {
//        const [isAuthenticated2, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])

//        console.log("before if statement: Logged in: " + isAuthenticated2)
//        if (isAuthenticated2) {
//            console.log("hit the get user")
//            const token = await authService.getAccessToken();
//            const username = user && user.name;
//            const userResponse = await window.fetch('api/AspNetUsers/user/' + username, {
//                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
//            });

//            const usersData = await userResponse.json();
//            console.log(usersData)
//            this.setState({
//                isAuthenticated2,
//                data: usersData.isServiceProvider,
//                //isAuthenticated2: isAuthenticated2
//            });
//        } else {
//            console.log("did not hit the get user")
//        }
//    }

//    render() {
//        const { data, isAuthenticated2 } = this.state;

//        console.log("IS Logged in?:1: " + isAuthenticated2)
//        console.log("IS Service Provider?:1: " + data)

//        let tagHome; let tagDonate; let tagMyItems; let tagRegister;

//        //this is for home
//        if (window.location.pathname === "/") {
//            tagHome = <a className="myBlue" href="/"><i className="fa fa-home fa-fw myBlue"></i><span className="brsmall"> Home </span></a>
//            tagDonate = <a href="/donate"><i className="fa fa-donate fa-fw"></i><span className="brsmall"> Donate</span></a>
//            tagMyItems = <a href="/Items"><i className="fa fa-list fa-fw"></i><span className="brsmall"> My Items</span></a>
//            tagRegister = <a href="/Identity/Account/Manage/Index"><i className="fa fa-registered fa-fw"></i><span className="brsmall"> Register</span></a>

//        }
//        //this is donate $
//        else if (window.location.pathname === "/donate") {
//            tagHome = <a href="/"><i className="fa fa-home fa-fw"></i><span className="brsmall"> Home</span></a>
//            tagDonate = <a className="myBlue" href="/donate"><i className="fa fa-donate fa-fw myBlue"></i><span className="brsmall"> Donate</span></a>
//            tagMyItems = <a href="/Items"><i className="fa fa-list fa-fw"></i><span className="brsmall"> My Items</span></a>
//            tagRegister = <a href="/Identity/Account/Manage/Index"><i className="fa fa-registered fa-fw"></i><span className="brsmall"> Register</span></a>

//        }
//        else if (window.location.pathname === "/Items") {
//            tagHome = <a href="/"><i className="fa fa-home fa-fw"></i><span className="brsmall"> Home</span></a>
//            tagDonate = <a href="/donate"><i className="fa fa-donate fa-fw"></i><span className="brsmall"> Donate</span></a>
//            tagMyItems = <a className="myBlue" href="/Items"><i className="fa fa-list fa-fw myblue"></i><span className="brsmall"> My Items</span></a>
//            tagRegister = <a href="/Identity/Account/Manage/Index"><i className="fa fa-registered fa-fw"></i><span className="brsmall"> Register</span></a>

//        }
//        //this is evething else 
//        else {
//            tagHome = <a href="/"><i className="fa fa-home fa-fw"></i><span className="brsmall"> Home</span></a>
//            tagDonate = <a href="/donate"><i className="fa fa-donate fa-fw"></i><span className="brsmall"> Donate</span></a>
//            tagMyItems = <a href="/Items"><i className="fa fa-list fa-fw"></i><span className="brsmall"> My Items</span></a>
//            tagRegister = <a href="/Identity/Account/Manage/Index"><i className="fa fa-registered fa-fw"></i><span className="brsmall"> Register</span></a>
//        }

//        const loginPath = `${ApplicationPaths.Login}`;

//        return this.myview(isAuthenticated2, data, tagHome, tagDonate, tagRegister, tagMyItems, loginPath);

//    }

//    myview(isAuthenticated2, data, tagHome, tagDonate, tagRegister, tagMyItems, loginPath) {
//        return (
//            <div id="wrapper">
//                <Route path="/" component={NavMenu} />

//                <Container>
//                    <div id="content">
//                        {this.props.children}
//                    </div>
//                </Container>
//                <Footer />
//                {console.log("IS Logged in?:2: " + isAuthenticated2)}
//                {console.log("IS Service Provider?:2: " + data)}
                
                
//                <nav className="mobile-bottom-nav">
//                    <div className="item item--active">
//                        <div className="item-content">
//                            {tagHome}
//                        </div>
//                    </div>
//                    <div className="item">
//                        <div className="item-content">
//                            {tagDonate}
//                        </div>
//                    </div>

//                    {(data === true && isAuthenticated2 === true) &&
//                        <div className="item">
//                            <div className="item-content">
//                                {tagMyItems}
//                            </div>
//                        </div>
//                    }
//                    {isAuthenticated2 === false &&
//                        <div className="item">
//                            <div className="item-content">
//                                {tagRegister}
//                            </div>
//                        </div>
//                    }
//                    <div className="item">
//                        <div className="item-content">
//                            {isAuthenticated2 === true &&
//                                <a href="/Identity/Account/Manage/Index"><i className="fa fa-user fa-fw"></i><span className="brsmall"> Account</span></a>
//                            }
//                            {isAuthenticated2 === false &&
//                                <a href="/authentication/login"><i className="fa fa-user fa-fw"></i><span className="brsmall"> Login</span></a>
//                            }
//                        </div>
//                    </div>
//                </nav>
//            </div>
//        );
//    }
//}